import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import { Collapse } from 'react-bootstrap';
import Validator from 'simple-react-validator';
import {
    getOrder,
    storeOrder,
    updateOrderDetail,
    updateShippingDetails,
    updatePricingDetail,
    storeKeepSakeOrder
} from 'redux/actions/order-actions';
import {getAgency} from 'redux/actions/agency-actions';
import Invoicing from 'components/FrontEnd/Order/OrderForm/Invoicing';
import ShippingDetails from 'components/FrontEnd/Order/OrderForm/ShippingDetails';
import ShippingTracking from 'components/FrontEnd/Order/OrderForm/ShippingTracking';
import Dates from 'components/FrontEnd/Order/OrderForm/Dates';
import GeneralInfo from 'components/FrontEnd/Order/OrderForm/GeneralInfo';
import User from 'helper/User';
import Pricing from 'components/FrontEnd/Order/OrderForm/Pricing';
import Documents from 'components/FrontEnd/Order/OrderForm/Documents';
import PhotoAndVideo from 'components/FrontEnd/Order/PhotoAndVideo';
import {clean, getRandomInt, convertMomentIntoDateObj, s3StaticPath, returnTimeStamp, helperFunctions as HF} from 'helper/helperFunctions';
import {getShippingSettings} from 'redux/actions/settings-actions';
import {cloneDeep, forEach, reduce} from 'lodash';
import ImagePreview from 'components/Upload/ImagePreview';
import Confirmalert from 'components/ThemeComponents/confirmAlert';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from 'jquery';
import {orderService} from "../../../../services/order";
import {getLatestCommentByCategoryTitle, setCommentForOrder} from "../../../../redux/actions/comment-actions";
import {getBrandingInfoBySlug} from "../../../../redux/actions/account-action";
import OrderWeTravelConfirm from './OrderWeTravelConfirm';
import OrderMoreButtonsList from './OrderMoreButtonsList';
import OrderComments from './OrderComments';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import SimpleReactValidator from 'simple-react-validator';
const moment = require('moment');

var dateFormat = require('dateformat');

// import MyImage from 'components/Upload/MyImage';

class OrderForm extends Component {
    constructor(props) {
        super(props);

        this.Validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            order_details: { ...OC.ORDERDETAIL },
            pricing_details: OC.PRICINGDETAIL,
            shipping_details: OC.SHIPPINGDETAIL,
            submitOrderDocuments: false,
            orderId:  'id' in this.props ? this.props.id : 0,
            currentShippingValue: 0,
            createdOrderBarcode: '',
            to_gl_comment: this.props.add_comment,
            weTravelButton: OC.MOREBUTTONS,
            uuid: getRandomInt(100000),
            is_france: false,
            vsa_is_france: false,
            ptma_is_france: false,
        }
        if (this.props.formType === 'edit' && 'id' in this.props) {
            this.props.getOrderCall(this.props.id);
        }
        this.props.getAgencyCall(User.getProperty('agency.id'));
        // this.Validator = new Validator();
        this.confirmAlert = React.createRef();
        this.clean = clean.bind(this);
        if ('reorder' in this.props) {
            this.props.getShippingSettings();
        }
    }

    weTravelUrlConfirm = (show_on_all_orders, link) => {
        // console.log(show_on_all_orders, link);
        if (+show_on_all_orders && (link === null || link === "" || link === " ")){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <OrderWeTravelConfirm
                            typeName="MoreButton"
                            description="Did you forget to add the WeTravel URL?"
                            onClosePro={onClose}
                            onNo={() =>
                                this.formSubmitHandler()
                            }
                            onYes={
                                () => this.setState({
                                    ...this.state,
                                    moreButtonCollapse: true
                                }, () => {
                                    let tp = $('#moreBtnContainer').offset().top + 50;
                                    $('body, html').animate({scrollTop: tp}, () =>{
                                        if(link){
                                            $('#moreBtn').css("border-color", "#bebebe")
                                        }else{
                                            $('#moreBtn').focus().css("border-color", "red")
                                        }
                                    });
                                })
                            }
                        />
                    )
                }
            })
        }else{
            // console.log(show_on_all_orders, link);
            $('#moreBtn').css("border-color", "#bebebe")
            this.formSubmitHandler()
        }
    }

    changeStatus = async (orderId, status, order_details, shipping_details, pricing_details) => {
        this.confirmAlert.current.props.onClosePro();
        await this.editUpdateOrder(order_details, shipping_details, pricing_details);
        orderService.updateOrderStatusByTitle(orderId, status)
    }

    closeConfirmAlert = (order_details, shipping_details, pricing_details) => {
        this.confirmAlert.current.props.onClosePro();
        this.editUpdateOrder(order_details, shipping_details, pricing_details);
    }
    confirmBox = (orderId, status, data, order_details, shipping_details, pricing_details) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <Confirmalert
                        ref={this.confirmAlert}
                        header={`This order was cancelled`}
                        description={`This order was cancelled on ${(this.state.comment) ? moment(this.state.comment.created_at).format("dddd, MMMM Do YYYY, hh:mm:ss a") : ''}, would you like to reactive this order?`}
                        onClosePro={onClose}
                        extraHtml={() => {
                            return (
                                <span>
                                    comment: {(this.state.comment) ? this.state.comment.comment : ''} <br/>
                                    Group Name : {data.group_name} <br/>
                                    Order # {data.barcode} <br/>
                                </span>
                            );
                        }}
                        onNo={() =>
                            this.closeConfirmAlert(order_details, shipping_details, pricing_details)
                        }
                        onYes={
                            () => this.changeStatus(orderId, status, order_details, shipping_details, pricing_details)
                        }
                        yesLabel='Reactivate Order'
                        noLabel='No'
                        doNotAutoCloseForYes
                    />
                )
            }
        })
    }

    formSubmitHandler = async () => {
        const prevDate = new Date(this.props.order_details.order_dates.return_date.value);
        const currentDate = new Date(this.state.order_details.order_dates.return_date.value)
        let return_date_changed = false;
        if (prevDate.getTime() !== currentDate.getTime()) {
            return_date_changed = true;
        }
        try{
            if (this.state.shipping_details.pre_trip_material_address.country.title === 'France') {

                this.Validator.fields.state = true;
                this.Validator.errorMessages.state = null;
            }
            if (this.state.shipping_details.video_shipping_address.country.title === 'France') {

                this.Validator.fields['video address state'] = true;
                this.Validator.errorMessages['video address state'] = null;
            }
            if (this.state.order_details.deliver_ptm_digital_only) {
                this.Validator.fields['street address '] = true;
                this.Validator.fields['city'] = true;
                this.Validator.fields['state'] = true;
            }
            if (this.state.order_details.deliver_keepsake_digital_only) {
                this.Validator.fields['video address street 1'] = true;
                this.Validator.fields['video address city'] = true;
                this.Validator.fields['video address state'] = true;

            }
        }catch (e) {
            console.log("eeeeeee", e);
        }
        if (this.Validator.allValid()) {
            
            const data = {
                order_details: {
                    ...cloneDeep(this.state.order_details),
                    customer_slates_for_videos_id: this.props.formType ==='add' ? User.getProperty('agency.id') : this.state.order_details.customer_slates_for_videos_id,
                    // customer_slates_for_videos_id: User.getProperty('agency.id'),
                    // agency_sales_rep_id: User.hasRole(Roles.AGENCY_USER_SC) ? User.getProperty('id') : this.props.editAgency.sales_rep_id
                },
                pricing_details: cloneDeep(this.state.pricing_details),
                shipping_details: cloneDeep(this.state.shipping_details),
                to_gl_comment: this.state.to_gl_comment,
                more_button_uuid: this.state.uuid,
                order_folders_uuid: this.state.uuid
            }
            forEach(data.order_details.order_dates, (item) => {
                if (item.value !== '')
                    item.value = dateFormat(convertMomentIntoDateObj(item.value), "yyyy-mm-dd HH:MM ")
            })
            
            console.log("data", data);
            if (this.props.formType === 'add' && this.props.reorder) {

                this.props.history.push({
                    pathname: '/checkout',
                    state: {data: data}
                })
            } else if (this.props.formType === 'add') {
                data.order_details.agency_creating_order = 1;

                this.props.storeOrderCall(data).then(
                    response => {
                        this.setState({
                            orderId: response.data.order_id,
                            createdOrderBarcode: response.data.barcode,
                            submitOrderDocuments: true
                        }, () => {
                            // this.props.history.push('/dashboard');
                            this.props.setCommentForOrder("");
                        });
                    }
                );
            } else if (this.props.formType === 'edit') {
                data.order_details.agency_updating_order = 1;
                data.order_details.return_date_changed = return_date_changed;
                const order_details = {order_details: data.order_details};
                const shipping_details = {shipping_details: data.shipping_details};
                const pricing_details = {pricing_details: data.pricing_details};


                if (this.props.order_details.status.title === 'Cancelled') {
                    await this.confirmBox(this.props.order_details.id, 'Approved', this.props.order_details, order_details, shipping_details, pricing_details)
                } else {
                    this.editUpdateOrder(order_details, shipping_details, pricing_details);
                }
            }
        } else {
            console.log("this.state.shipping_details", this.state.shipping_details);
            console.log("showErrorMessages", this.Validator.fields);
            
            this.Validator.showMessages();
            this.setState(prevState => ({
                ...prevState,
                showErrorMessages: this.Validator.messagesShown
            }), () => {
                $('html, body').animate({
                    scrollTop: $(".srv-validation-message").offset().top
                }, 500);
            })
        }
    }

    editUpdateOrder = async (order_details, shipping_details, pricing_details) => {
        const updateOrder = this.props.updateOrderCall;
        const updateShipping = this.props.updateShippingDetailsCall;
        const updatePricing = this.props.updatePricingDetailCall;

        const res = await Promise.all([updateOrder(order_details, this.props.id), updateShipping(this.props.id, shipping_details,
            updatePricing(pricing_details,this.props.id))])
        // this.props.updateOrderCall(order_details, this.props.id)
        //     .then(
        //         response => {
        //
        //         }
        //     );
        // await this.props.updateShippingDetailsCall(this.props.id, shipping_details);
        // await this.props.updatePricingDetailCall(pricing_details, this.props.id);
        this.setState({
            submitOrderDocuments: true
        }, () => {
            this.setState({
                submitOrderDocuments: false
            })
            // this.props.history.push('/dashboard');
        });
        return res;
    }

    getChildState = ( childState ) => {
        this.setState({
            weTravelButton: childState
        })
    }

    componentDidMount() {
        this.props.getLatestCommentByCategoryTitle('cancel', this.props.match.params.id).then(res => {
            this.setState({
                comment: res.data.comment
            })
        });
        this.setState(prevState => {
            return {
                ...prevState,
                order_details: {
                    ...prevState.order_details,
                    agency_id: this.props.agency_id,
                    agency_type_id: User.agency('agency_type_id'),
                    agency_sales_rep_id: User.getProperty('id')
                }
            }
        })


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            });
        }
        if (this.props.add_comment !== prevProps.add_comment) {
            this.setState({
                to_gl_comment: this.props.add_comment
            });
        }
        if (this.props.pricing_details !== prevProps.pricing_details) {
            this.setState({
                pricing_details: this.props.pricing_details
            });
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            });
        }
        // if (this.props.editAgency !== prevProps.editAgency) {
        //     this.setState({
        //         order_details: {
        //             ...prevState.order_details,
        //             agency: this.props.editAgency
        //         }
        //     })
        // }
        if (this.props.shippingSettings !== prevProps.shippingSettings) {
            this.setState({
                currentShippingValue: +this.props.shippingSettings.normal_production.value
            })
        }
    }

    getState = (state, container) => {
        // if(container==='order_details')
        // console.log(state.agency.primary_address);
        this.setState(prevState => ({
            ...prevState,
            [container]: state
        }))
    }

    productionChangeHandler = (e) => {
        let order_totals = cloneDeep(this.state.pricing_details.order_totals);
        const order_items = cloneDeep(this.state.pricing_details.order_items);
        let {value, name, checked} = e.target;
        value = Number(value);
        let keyName = name;
        let subtotal = reduce(order_items, (subtotal, current) => {
            return subtotal + (Number(current.quantity) * Number(current.price))
        }, 0);
        let grand_total = subtotal;
        let shipping = 0;
        if (Number(order_items['PhotoMozaix™'].quantity) === 1) {
            shipping = Number(this.props.shippingSettings.mosaic_shipping.value);
        }
        if (name === 'overnight_shipping') {
            keyName = 'overnight';
            if (checked) {
                if (keyName in order_totals) {
                    order_totals[keyName].value = value;
                    shipping += value
                } else {
                    order_totals[keyName] = {
                        code: 'overnight',
                        value: value,
                        title: 'overnight_production'
                    }
                    shipping += value
                }
            } else {
                order_totals[keyName].value = 0
            }
            if ('production' in order_totals) {
                shipping += order_totals.production.value
            }
        } else {
            if (checked) {
                if (keyName in order_totals) {
                    order_totals[keyName].value = value;
                    shipping += value
                } else {
                    order_totals[keyName] = {
                        code: 'production',
                        value: value,
                        title: Object.keys(this.props.shippingSettings).find(item => {
                            return parseFloat(this.props.shippingSettings[item].value) === value;
                        })
                    }
                    shipping += value
                }
            } else {
                order_totals[keyName].value = 0
            }
            if ('overnight' in order_totals) {
                shipping += order_totals.overnight.value
            }
        }
        grand_total -= order_totals.discount ? Number(order_totals.discount.value) : 0
        grand_total += shipping;
        order_totals.shipping.value = shipping;
        order_totals.grand_total.value = grand_total
        this.setState((prevState) => {
            return {
                ...prevState,
                pricing_details: {
                    ...prevState.pricing_details,
                    order_totals: order_totals
                }
            }
        })
    }
    getChildrenStateVsa = (state) =>{
        this.setState({
            vsa_is_france: state
        })
    }
    getChildrenStatePtma = ( childrenState ) => {
        this.setState({
            is_france: childrenState,
            ptma_is_france : childrenState

        }, () => {
            // console.log(this.state.is_france, 'is not');
            //     let event = new MouseEvent('click', {
        //         'bubbles': true,
        //         'cancelable': false
        //     })
        //     var node1 = document.getElementById('france_1');
        //     // var node2 = document.getElementById('france_2');
        //     console.log("🚀 ~ file: AgencyForm.jsx ~ line 580 ~ AgencyFormPage ~ node1", node1)
        //     // console.log("🚀 ~ file: AgencyForm.jsx ~ line 581 ~ AgencyFormPage ~ node2", node2)
        //     node1.dispatchEvent(event);
        //     // setTimeout(() => {
        //     //     node2.dispatchEvent(event);
        //     // }, 0);
        })
    }
    // getinitialState = ( initial_country_France ) => {
    //     this.setState({
    //         initial_country_France: initial_country_France,
    //     })
    // }

    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.shipping_details) {
    //     //   const company = Object.assign({}, this.state.company);
    //     //   company.id = 0;
    //     //   company.messages = [];
    //     //   this.setState({company: company});

    //       const shipping_details = Object.assign({}, this.state.shipping_details);
    //         if(shipping_details && shipping_details.pre_trip_material_address && shipping_details.pre_trip_material_address.country && shipping_details.pre_trip_material_address.country.title && shipping_details.pre_trip_material_address.country.title === 'France')
    //         {
    //             this.setState({
    //                 initial_country_France: true
    //             })
    //         }
    //         console.log('componentWillReceiveProps shipping_details', this.state.shipping_details);
    //     }

    //   }

    addDefaultSrc = (ev) => {
        ev.target.src = HF.s3StaticPath('img/logo-placeholder.png');
    }

    render() {
        // this.Validator.purgeFields();
        let gtv = '';
        gtv = this.props.brandingInfo;
        let gtv_logo = gtv && gtv.account_resources && gtv.account_resources.logo_file && gtv.account_resources.logo_file.file_path
        let file_title = gtv && gtv.account_resources && gtv.account_resources.logo_file && gtv.account_resources.logo_file.file_title

        // this.Validator.purgeFields();
        const {formType, shippingSettings, editAgency, columns , france,} = this.props;
        const {shipping_details, order_details, pricing_details, is_france, vsa_is_france, ptma_is_france} = this.state;
        const settingsNeed = ['normal_production', 'fast_production', 'same_day_production', 'overnight_production'];
        // console.log("Shipping details: " ,initial_country_France);
        // console.log(initial_country_France,'  ---orderForm getinitialState');
       let initial_country_France= false;
       let ptma_initial_country_France= false;
       let vsa_initial_country_France= false;
        if (shipping_details && shipping_details.pre_trip_material_address && shipping_details.pre_trip_material_address.country && shipping_details.pre_trip_material_address.country.title && shipping_details.pre_trip_material_address.country.title === 'France'){
            initial_country_France = true;
            ptma_initial_country_France = true;
        }
        if (shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.country && shipping_details.video_shipping_address.country.title && shipping_details.video_shipping_address.country.title === 'France'){

            vsa_initial_country_France = true;
        }
        let is_departured = moment(order_details.order_dates.departure_date.value).diff(moment.now(), 'days') < 0 ? true : false;

                // console.log('orderForm is_france---     : ', this.state.is_france);
        return (
            <React.Fragment>
               { this.props.reorder ?
                    <img src={gtv_logo + file_title} alt="logo" onError={this.addDefaultSrc} style={{maxHeight: "127px"}}/>
                    :
                    <img src={User.agencyLogo() ? User.agencyLogo() : s3StaticPath('img/placeholder.png')} alt="Logo name" onError={this.addDefaultSrc}
                            style={{maxHeight: "127px"}}/>
                }
                <div className="row mb-xlg">
                    <div className="col-md-6">
                        {
                            !('reorder' in this.props) &&
                            <GeneralInfo orderForm notEditable={this.props.notEditable}
                                         showErrorMessages={this.state.showErrorMessages}
                                         validatorInstance={this.Validator} returnState={this.getState}
                                         order_details={order_details}
                                         is_france={is_france}
                                         notEditableFrance={france && france}
                                         vsa_is_france={vsa_is_france}
                                         ptma_is_france={ptma_is_france}
                                         initial_country_France={initial_country_France}
                                         ptma_initial_country_France={ptma_initial_country_France}
                                         vsa_initial_country_France={vsa_initial_country_France}
                                         />
                        }
                        {
                            this.props.notEditable === undefined &&
                            <ShippingDetails formType={this.props.formType} notEditable={this.props.notEditable}
                                             order_details={order_details} reorder={this.props.reorder}
                                             showErrorMessages={this.state.showErrorMessages}
                                             validatorInstance={this.Validator} returnState={this.getState}
                                             agencyAddress={this.props.editAgency.primary_address}
                                             shipping_details={shipping_details}
                                             childrenStatePtma={this.getChildrenStatePtma}
                                             childrenStateVsa={this.getChildrenStateVsa}
                                             getinitialState={this.getinitialState}
                                             ptma_initial_country_France={ptma_initial_country_France}
                                             vsa_initial_country_France={vsa_initial_country_France}
                                             is_departured={is_departured}
                                             />
                        }
                        {
                            this.props.notEditable &&
                            <Dates notEditable={this.props.notEditable} showErrorMessages={this.state.showErrorMessages}
                                   validatorInstance={this.Validator} returnState={this.getState}
                                   order_details={order_details}/>
                        }
                        {
                            this.props.notEditable &&
                            (
                                shipping_details &&
                                ('camera_ship_status' in shipping_details && 'video_ship_status' in shipping_details) &&
                                (shipping_details.camera_ship_status != null || shipping_details.video_ship_status != null)
                            )
                            &&
                            <ShippingTracking
                                formType={this.props.formType}
                                notEditable={this.props.notEditable}
                                reorder={this.props.reorder}
                                showErrorMessages={this.state.showErrorMessages}
                                validatorInstance={this.Validator}
                                returnState={this.getState}
                                agencyAddress={this.props.editAgency.primary_address}
                                shipping_details={shipping_details}
                            />
                        }
                        {
                            'agency' in order_details &&
                            formType === 'edit'
                                ?
                                <Pricing notEditable={this.props.notEditable}
                                         mosaicShipping={this.props.shippingSettings.mosaic_shipping}
                                         reorder={this.props.reorder} formType={formType}
                                         showErrorMessages={this.state.showErrorMessages}
                                         validatorInstance={this.Validator} /*state={shipping_details.pre_trip_material_address.state.label}*/
                                         state={'primary_address' in order_details.agency ? order_details.agency.primary_address.state && order_details.agency.primary_address.state.title &&  order_details.agency.primary_address.state.title : ''}
                                         returnState={this.getState} pricing_details={this.props.pricing_details}
                                         agency={editAgency}
                                         is_departured={is_departured}
                                         />
                                :
                                <Pricing mosaicShipping={this.props.shippingSettings.mosaic_shipping}
                                         reorder={this.props.reorder} formType={formType}
                                         showErrorMessages={this.state.showErrorMessages}
                                         validatorInstance={this.Validator} /*state={shipping_details.pre_trip_material_address.state.label}*/
                                         state={'primary_address' in order_details.agency ? order_details.agency.primary_address.state.title : ''}
                                         returnState={this.getState} pricing_details={pricing_details}
                                         order_details={order_details}
                                         agency={editAgency}/>
                        }
                    </div>
                    <div className="col-md-6">
                        {
                            ('id' in order_details && this.props.notEditable) &&
                            <PhotoAndVideo
                                notEditable={this.props.notEditable}
                                order_details={order_details}
                            />
                        }

                        {
                            'orderResources' in order_details
                                ?
                                '20x30_mosaic_preview' in order_details.orderResources
                                    ?
                                        <div className="form_tile">
                                            <div className="form_tile_heading">
                                                <img src={s3StaticPath('img/shipping_information.png')} alt="Dates"/>
                                                <span>Mozaix Preview</span>
                                            </div>
                                            <ImagePreview
                                                filesToPreview={{
                                                    preview: order_details.orderResources['20x30_mosaic_preview'].file_path + '?rand=' + returnTimeStamp(order_details.orderResources['20x30_mosaic_preview'].updated_at),
                                                    name: order_details.orderResources['20x30_mosaic_preview'].file_title,
                                                    type: order_details.orderResources['20x30_mosaic_preview'].mime_type,
                                                    id: order_details.orderResources['20x30_mosaic_preview'].id,
                                                }}
                                                width={{width:'auto'}}
                                                height={{ height:'auto'}}
                                            />
                                            <DownloadFile extraClass='linkColor downloadIconHeight text-center mb-md ml-md' space={'&nbsp;&nbsp;&nbsp;'} multiple={false} title={'Mozaix Preview'} files={{
                                                'file_title': order_details.orderResources['20x30_mosaic_preview'].file_title,
                                                'file_path': order_details.orderResources['20x30_mosaic_preview'].file_path
                                            }} />
                                        </div>
                                    :
                                    null
                                :
                                null
                        }
                        {
                            !('reorder' in this.props) &&
                            <React.Fragment>
                                {
                                    this.props.notEditable === undefined &&
                                    <Dates notEditable={this.props.notEditable}
                                           showErrorMessages={this.state.showErrorMessages}
                                           validatorInstance={this.Validator} returnState={this.getState}
                                           order_details={order_details}/>
                                }

                               { formType === 'add' ?
                                <OrderComments
                                    createdOrderBarcode={this.state.createdOrderBarcode}
                                    notEditable={this.props.notEditable}
                                    formSubmitted={this.state.submitOrderDocuments} id={this.state.orderId}
                                    formType={formType}  />
                                : ''
                                }

                                     <Documents createdOrderBarcode={this.state.createdOrderBarcode}
                                               notEditable={this.props.notEditable}
                                               formSubmitted={this.state.submitOrderDocuments} id={this.state.orderId}
                                               formType={formType} isSchedule={true} resourceTypeText={'order_documents'}
                                               agency_id={User.agency('id')}
                                               uuid={this.state.uuid}
                                               withUuid={true}
                                               cannotUpload={parseInt(order_details.settings.finalize_by_qa.value) ? true : false}/>



                                {

                                <div className="form_tile mb-2">
{/*
                                    <div className="form_tile_heading flexElem alignCenter spaceBetween" id="moreBtnContainer" style={{cursor: 'pointer'}} onClick={() => {this.setState({moreButtonCollapse: !this.state.moreButtonCollapse})}}>
                                        <div className="flexElem alignCenter">
                                            <img src={HF.s3StaticPath('img/video_titles.png')} alt="Video Title" />
                                            <span>List of 'More' Buttons on App</span>
                                        </div>

                                        <div>
                                            <i className="fa fa-chevron-down" style={{fontSize: 'smaller', transition: '0.2s all ease', transform: this.state.moreButtonCollapse ? 'rotate(180deg)' : 'rotate(0deg)'}}></i>
                                        </div>
                                    </div>

                                    <Collapse in={this.state.moreButtonCollapse}>
                                        <div className="form_tile_content" log={console.log('we travel btn', this.state.weTravelButton)}> */}
                                            <OrderMoreButtonsList
                                                columns={columns}
                                                agency_id={User.agency('id')}
                                                agency={this.props.editAgency}
                                                order_id={this.state.orderId}
                                                order_details={order_details}
                                                uuid={this.state.uuid}
                                                withUuid={true}
                                                childState={this.getChildState}
                                                // showErrorMessages={this.state.showErrorMessages}
                                                // validatorInstance={this.Validator} returnState={this.getState}
                                            />
                                            <div className="clearfix"></div>
                                        {/* </div>
                                    </Collapse> */}

                                </div>

                                }

                                {
                                    this.props.notEditable === undefined &&
                                    <Invoicing/>
                                }
                            </React.Fragment>
                        }


                        {
                            this.props.notEditable &&
                            <ShippingDetails notEditable={this.props.notEditable} order_details={order_details}
                                             reorder={this.props.reorder}
                                             showErrorMessages={this.state.showErrorMessages}
                                             validatorInstance={this.Validator} returnState={this.getState}
                                             agencyAddress={this.props.editAgency.primary_address}
                                             shipping_details={shipping_details}
                                             ptma_initial_country_France={ptma_initial_country_France}
                                             vsa_initial_country_France={vsa_initial_country_France}/>
                        }


                    </div>
                    {
                        ('reorder' in this.props) &&
                        <div className='col-md-12'>
                            <div className="form_tile">
                                <div className="form_tile_heading">
                                    {/* <img src={invoicing} alt="Invoicing" /> */}
                                    <span>Production &amp; Shipping Options</span>
                                </div>
                                <div className="form_tile_content">
                                    <div className="flexElem respFlex spaceBetween form_tile_row">
                                        <div className="col-md-12">
                                            {/* <div className="form_tile_title">When invoiced, please make checks payable and remit to:</div> */}
                                            <div className="reponsive-table form_tile_row">
                                                <table className="table_tile">
                                                    <tbody>
                                                    {
                                                        Object.keys(shippingSettings).map(item => {
                                                            if (settingsNeed.includes(item)) {
                                                                return (
                                                                    <tr key={item}
                                                                        style={{borderBottom: item === 'same_day_production' && '5px solid #777'}}>
                                                                        <td width="25%"><strong
                                                                            className='text-capitalize'>{item === 'overnight_production' ? 'Overnight Shipping' : shippingSettings[item].key.replace(/_/g, ' ')}</strong>
                                                                        </td>
                                                                        <td colSpan='2'>{shippingSettings[item].description}</td>
                                                                        <td className='text-center'>
                                                                            {
                                                                                item === 'overnight_production' ?
                                                                                    <div className='customChk'>
                                                                                        <input type='checkbox'
                                                                                               onChange={this.productionChangeHandler}
                                                                                               value={shippingSettings[item].value}
                                                                                               name='overnight_shipping'
                                                                                               id='overnight_production'/>
                                                                                        <label
                                                                                            htmlFor="overnight_production"></label>
                                                                                    </div>
                                                                                    :
                                                                                    <div className='customChk'>
                                                                                        <input
                                                                                            value={shippingSettings[item].value}
                                                                                            defaultChecked={item === 'normal_production'}
                                                                                            onChange={this.productionChangeHandler}
                                                                                            type="radio"
                                                                                            name='production'
                                                                                            id={item}/>
                                                                                        <label htmlFor={item}></label>
                                                                                    </div>
                                                                            }
                                                                        </td>
                                                                        <td>${parseFloat(shippingSettings[item].value).toFixed(2)}</td>
                                                                    </tr>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                    <tr>
                                                        <td className='text-right' colSpan='4'>Mosaic Discount</td>
                                                        <td className='text-primary'>${pricing_details.order_totals.discount ? parseFloat(pricing_details.order_totals.discount.value).toFixed(2) : "0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right' colSpan='4'>Product and Shipping</td>
                                                        <td className='text-primary'>${pricing_details.order_totals.shipping ? parseFloat(pricing_details.order_totals.shipping.value).toFixed(2) : "0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right' colSpan='4'>Total</td>
                                                        <td className='text-primary'>${pricing_details.order_totals.grand_total ? parseFloat(pricing_details.order_totals.grand_total.value).toFixed(2) : "0.00"}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-4 col-md-offset-4">
                        {

                            this.props.notChangeAble === undefined
                                ?
                                this.props.notEditable
                                    ?
                                    <React.Fragment>
                                        <Link to={`/edit_order/${this.props.match.params.id}`}
                                              className="themeBtn_14 pl-xlg pr-xlg mt-xlg mb-xlg blue_theme_button hover_dark">Edit
                                            Order</Link>
                                        <Link to={`/order-options/${this.props.match.params.id}`}
                                              className="themeBtn_14 mt-xlg mb-xlg pl-xlg pr-xlg blue_theme_button hover_dark ml-md">Order
                                            Options</Link>
                                    </React.Fragment>
                                    :
                                    <button onClick={() => this.weTravelUrlConfirm(this.state.weTravelButton && this.state.weTravelButton.show_on_all_orders, this.state.weTravelButton && this.state.weTravelButton.link)}
                                            className="themeBtn_14 block mt-xlg mb-xlg blue_theme_button hover_dark">{formType === 'add' ? this.props.reorder ? 'Continue' : 'Save Order' : 'Update Order'}</button>
                                :
                                null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {editAgency} = state.agenciesReducer;

    const {order_details, shipping_details, pricing_details, production_details} = state.ordersReducer;
    const {shippingSettings} = state.frontEndUserReducer
    const {add_comment} = state.commentReducer
    const { brandingInfo } = state.accountReducer;
    return {
        order_details,
        shipping_details,
        pricing_details,
        editAgency,
        production_details,
        shippingSettings,
        add_comment,
        brandingInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyCall: (id) => {
            dispatch(getAgency(id))
        },
        getOrderCall: (orderId) => {
            dispatch(getOrder(orderId))
        },
        storeOrderCall: (orderData) => {
            return dispatch(storeOrder(orderData))
        },
        updateOrderCall: (data, id) => {
            return dispatch(updateOrderDetail(data, id))
        },
        updateShippingDetailsCall: (orderId, Data) => {
            dispatch(updateShippingDetails(orderId, Data))
        },
        updatePricingDetailCall: (data, id) => {
            dispatch(updatePricingDetail(data, id))
        },
        getShippingSettings: () => {
            dispatch(getShippingSettings())
        },
        storeKeepSakeOrder: (data) => dispatch(storeKeepSakeOrder(data)),
        getLatestCommentByCategoryTitle: (title, model_id) => {
            return dispatch(getLatestCommentByCategoryTitle(title, model_id))
        },
        getBrandingInfoBySlug: (slug) => {return dispatch(getBrandingInfoBySlug(slug)) },
        setCommentForOrder: (comment) => { return dispatch(setCommentForOrder(comment)) }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderForm))
