import React, { Component } from 'react'
import { connect } from 'react-redux'
import AgencyLogo from 'components/ThemeComponents/AgencyLogo';
import { openModal, closeModal } from 'helper/helperFunctions';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import Documents from 'components/FrontEnd/Order/OrderForm/Documents';
import { Roles } from '../../../constants/Roles';
import User from 'helper/User';
var Modal = require('react-bootstrap-modal');

class OrderUploadInfoForVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addDocumentModal: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.props.orderResourcesDocuments !== prevProps.orderResourcesDocuments) {
        //     this.setState({ addDocumentModal: false });
        // }
    }

    handleClose() {
        console.log('reloadPage', this.state.addDocumentModal);
        if(this.state.addDocumentModal === true) {
            // window.location.reload();
            this.setState({
                addDocumentModal: false
            })
        }
        // window.location.assign("/uploader");
        // window.location.reload();
        // closeModal.bind(this, "addDocumentModal");
        // setShow(false)
    }
    render() {
        const { orderUploadInfo } = this.props;
        const { addDocumentModal } = this.state;
        return (
            <React.Fragment>
                <div className="uploader_box_col small">
                    <div className="companyContainer flexElem respFlex alignCenter">
                        <div className='agencyLogoContainer'><AgencyLogo className="companyLogo" /></div>
                        <div className="ml-sm mr-sm">
                            <div className="companyName text_22">{orderUploadInfo.group_name}</div>
                            <div className="cityName text_16">{orderUploadInfo.destinations}</div>
                        </div>
                    </div>

                    <div className="uploadDetails pl-sm mt-lg">
                        <table>
                            <tbody>
                                <tr>
                                    <td><span>Total Videos{User.hasRole(Roles.TRAVELER) && ' in Gallery'}:</span></td>
                                    <td className="pl-sm text-right"><span className="linkColor">{orderUploadInfo.videos_count}</span></td>
                                </tr>
                                {
                                    User.hasRole(Roles.PHOTO_ADMIN) &&
                                    <>
                                        <tr>
                                            <td><span>Total Videos Approved for Keepsake:</span></td>
                                            <td className="pl-sm text-right"><span className="linkColor">{orderUploadInfo.total_keepsake_videos}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className='text-danger'>Total Videos Not Approved for Keepsake:</span></td>
                                            <td className="pl-sm text-right"><span className="linkColor">{orderUploadInfo.total_non_keepsake_videos}</span></td>
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <td><span>Max Videos Allowed:</span></td>
                                    <td className="pl-sm text-right"><span className="linkColor">{orderUploadInfo.max_allowed_videos.value}</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Upload Cut Off Date for Attendees:</span><br />
                                        <span style={{fontSize:'11px'}}>Wedding Couple has an extra 2 days to add videos and review order</span>
                                    </td>
                                    <td className="pl-sm text-right"><span className="linkColor">{orderUploadInfo.upload_cut_off_date_formatted}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-xlg flexElem spaceBetween alignCenter pl-md">
                        {/* {
                            !User.hasRole(Roles.TRAVELER)
                                ?
                                <OpenModalButton
                                    openModal={openModal.bind(this, "addDocumentModal")}
                                    classButton={['themeBtn_12 blue_theme_button hover_dark']}
                                    buttonName="ADD Documents"
                                    tooltipText="ADD Documents"
                                    classIcon={[]}
                                />
                                :
                                null
                        } */}
                        <a href='#companyImagesInfo' className="themeBtn_12 hover_dark blue_theme_button">View Uploaded Videos</a>
                    </div>
                </div>

                <Modal backdrop={'static'} show={addDocumentModal} onHide={closeModal.bind(this, "addDocumentModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Add Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Documents
                            notEditable
                            formSubmitted={this.state.submitOrderDocuments}
                            id={orderUploadInfo.id}
                            formType={'add'}
                            isSchedule={false}
                            header={'Add Documents'}
                            agency_id={User.agency('id')}
                            isPopUpForm={true}
                            resourceTypeText={'order_documents'}
                        />
                    </Modal.Body>
                </Modal>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { orderResourcesDocuments } = state.ordersReducer;
    return {
        orderResourcesDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderUploadInfoForVideo)
