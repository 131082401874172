import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker";

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { dateChangeHandler, s3StaticPath, getFormattedDateMoment } from 'helper/helperFunctions';
import { Roles } from '../../../../constants/Roles';
import User from 'helper/User';
// import MyImage from 'components/Upload/MyImage';
const moment = window.moment;
var cloneDeep = require('lodash.clonedeep');

class Dates extends Component {

    constructor(props) {
        super(props)

        this.state = {
            order_details: this.props.order_details
        }
        this.dateChangeHandler = dateChangeHandler.bind(this);
        this.onChangeOrderDate = this.onChangeOrderDate.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            })
        }
        if (this.state.order_details !== prevState.order_details) {
            this.returnState();
        }
        if (this.props.showErrorMessages && this.props.showErrorMessages !== prevProps.showErrorMessages) {
            this.props.validatorInstance.showMessages();
        }
    }

    returnState = () => {
        this.props.returnState(this.state.order_details, 'order_details');
    }

    getMinDate = (field) => {
        let departure_date = this.state.order_details.order_dates.departure_date.value
        let return_date = cloneDeep(this.state.order_details.order_dates.return_date.value)
        if (departure_date && return_date) {
            switch (field) {
                case 'return_date':
                    let new_return_date = new Date(departure_date);
                    return this.getPlainDateObject(new_return_date);
                // break;
                case 'video_special_need_date':
                    let new_date = new Date(return_date);
                    return moment.isMoment(new_date) ? moment(new_date).add(1, 'days').toDate() : new_date.setDate(new_date.getDate() + 1);
                // break;
                default:
                    return null;
            }
        }
    }
    dateElement = (label, name, value, rules, type = 'text') => {

        var selectedDate = this.state.order_details.order_dates[name]['value'] !== '' ? new Date(this.state.order_details.order_dates[name]['value']) : '';
        //console.log('selectedDate front end', selectedDate)
        return (
            <React.Fragment>
                <DatePicker
                    autoComplete='off'
                    placeholderText='MM/DD/YYYY'
                    className="form_tile_input date_input block"
                    selected={selectedDate}
                    name={name}
                    // onChange={(event) => this.dateChangeHandler(event, `order_details.order_dates.${name}.value`, '')}
                    onChange={(event) => this.onChangeOrderDate(event, name)}
                    minDate={this.getMinDate(name)}
                    maxDate={name === 'pre_trip_package_need_date' ? this.getPlainDateObject(this.state.order_details.order_dates['departure_date'].value) : null}
                />
                {
                    value &&
                    this.props.validatorInstance.message(name, value, rules)
                }
            </React.Fragment>
        );
    }
    getPlainDateObject = (value) => {
        return moment.isMoment(value) ? value.toDate() : value
    }

    onChangeOrderDate(value, field) {
        var valueDate = moment(value).startOf('day');
        var tempDate = cloneDeep(this.state.order_details);
        tempDate.order_dates[field].value = value === null ? '' : valueDate;
        //console.log('test one', tempDate.order_dates['return_date'].value);
        if (field === 'departure_date') {
            tempDate.order_dates['return_date'].value = value === null ? '' : tempDate.order_dates['return_date'].value < valueDate ? valueDate : new Date(tempDate.order_dates['return_date'].value);
            //console.log('test two', tempDate.order_dates['return_date'].value);
        }
        this.setState(
            {
                order_details: tempDate
            }, () => {
                let order_dates_clone = cloneDeep(this.state.order_details)
                let video_special_need_date = this.getPlainDateObject(order_dates_clone.order_dates.video_special_need_date.value)
                let return_date = this.getPlainDateObject(order_dates_clone.order_dates.return_date.value)
                if (field === 'return_date') {
                    if (video_special_need_date && return_date >= video_special_need_date) {
                        order_dates_clone.order_dates['video_special_need_date'].value = value === null ? '' : valueDate.add(1, 'days');
                        this.setState(
                            {
                                order_details: order_dates_clone
                            })
                    }
                }
            }
        );
    }


    render() {
        const { order_details } = this.state;
        let d = ((order_details.order_dates.departure_date.value) !== "") ? moment(order_details.order_dates.departure_date.value).format('YYYY/MM/DD') : "";
        const { notEditable } = this.props;
        return (
            <div className="form_tile">
                <div className="form_tile_heading">
                    <img src={s3StaticPath('img/dates.png')} alt="Dates" />
                    <span>Dates</span>
                </div>

                <div className="form_tile_content">
                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4">Wedding Festivities Start Date *</div>
                        <div className="col-md-8 text-primary">
                            {
                                notEditable
                                    ?
                                    // d
                                    getFormattedDateMoment(order_details.order_dates.departure_date.value)
                                    :
                                    this.dateElement('Wedding Festivities Start Date', 'departure_date', order_details.order_dates.departure_date['value'] !== '' && moment(order_details.order_dates.departure_date['value'], 'YYYY-MM-DD'), ['required', 'date'], 'date')


                            }
                            {this.props.validatorInstance.message('Wedding Festivities Start Date', order_details.order_dates.return_date.value, 'required')}
                        </div>
                    </div>
                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4">
                            Wedding Festivities End Date *
                        </div>
                        <div className="col-md-8 text-primary">
                            {
                                notEditable
                                    ?
                                    getFormattedDateMoment(order_details.order_dates.return_date.value)
                                    :
                                    this.dateElement('Wedding Festivities End Date', 'return_date', order_details.order_dates.return_date['value'] !== '' && moment(order_details.order_dates.return_date['value'], 'YYYY-MM-DD'), ['required', 'date', { after_or_equal: moment(order_details.order_dates.departure_date['value'], 'YYYY-MM-DD') }], 'date')
                            }
                            {this.props.validatorInstance.message('Wedding Festivities End Date', order_details.order_dates.return_date.value, ['required', 'date', { after_or_equal: moment(order_details.order_dates.departure_date['value'], 'MM/DD/YYYY') }])}

                        </div>
                    </div>
                    {/* <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4">
                            Last Day of School
                                    </div>
                        <div className="col-md-8 text-primary">
                            {
                                notEditable ?
                                    getFormattedDate(order_details.order_dates.last_day_of_school.value)
                                    :
                                    <DatePicker
                                        autoComplete='off'
                                        placeholderText='MM/DD/YYYY'
                                        className="form_tile_input date_input block"
                                        onChange={(event) => this.dateChangeHandler(event, 'order_details.order_dates.last_day_of_school.value')}
                                        selected={order_details.order_dates.last_day_of_school.value ? new Date(order_details.order_dates.last_day_of_school.value) : null}
                                        name="last_day_of_school" />
                            }

                        </div>
                    </div> */}
                    {/* {
                        notEditable &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">
                                Video Need Date
                            <OverlayTrigger
                                    overlay={
                                        <Tooltip id='video_date'>A date following return by which the videos must arrive.</Tooltip>
                                    }>
                                    <span className="tipIcon">?</span>
                                </OverlayTrigger>
                            </div>
                            <div className="col-md-8 text-primary">
                                {
                                    notEditable ?
                                        getFormattedDate(order_details.order_dates.video_special_need_date.value)
                                        :
                                        <DatePicker
                                            autoComplete='off'
                                            placeholderText='MM/DD/YYYY'
                                            className="form_tile_input date_input block"
                                            onChange={(event) => this.dateChangeHandler(event, 'order_details.order_dates.video_special_need_date.value')}
                                            selected={order_details.order_dates.video_special_need_date.value ? new Date(order_details.order_dates.video_special_need_date.value) : null}
                                            name="video_special_need_date" />
                                }

                            </div>
                        </div>
                    } */}

                    {/* <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4">
                            Pre Trip Materials Need Date
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id='video_date'>A date prior to departure by which the pre trip materials must arrive.</Tooltip>
                                }>
                                <span className="tipIcon">?</span>
                            </OverlayTrigger>
                        </div>
                        <div className="col-md-8 text-primary">
                            {
                                notEditable ?
                                getFormattedDateMoment(order_details.order_dates.pre_trip_package_need_date.value)
                                    :
                                    User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) ?
                                        <p>Please enter Pre Trip Materials need date in the comments box.</p> :
                                        <DatePicker
                                            autoComplete='off'
                                            placeholderText='MM/DD/YYYY'
                                            className="form_tile_input date_input block"
                                            onChange={(event) => this.dateChangeHandler(event, 'order_details.order_dates.pre_trip_package_need_date.value')}
                                            selected={order_details.order_dates.pre_trip_package_need_date.value ? new Date(order_details.order_dates.pre_trip_package_need_date.value) : null}
                                            name="pre_trip_package_need_date" />
                            }
                        </div>
                    </div> */}
                    {
                        /*notEditable === undefined &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">
                                Pre Trip Materials Need Date
                            <OverlayTrigger
                                    overlay={
                                        <Tooltip id='pre_trip_date'>A date prior to departure by which the pre trip materials must arrive.</Tooltip>
                                    }>
                                    <span className="tipIcon">?</span>
                                </OverlayTrigger>
                            </div>
                            <div className="col-md-8">
                                Please enter Pre Trip Materials need date in the comments box.
                        </div>
                        </div>
                    */}

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Dates)
