import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { getAgencyOrders } from 'redux/actions/agency-actions';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import User from 'helper/User';
import { inputChangeHandler, s3StaticPath, toggleModal, returnDynamicURL } from 'helper/helperFunctions';
import { orderService } from 'services/order';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
// import { orderService } from '../../services/order';
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
// import quickLogin from 'assets/img/quick_login.png';

import DownloadFile from 'components/ThemeComponents/downloadFile';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
// import MyImage from 'components/Upload/MyImage';
import Pagination from 'components/ThemeComponents/Pagination';
import Confirmalert from 'components/ThemeComponents/confirmAlert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SimpleReactValidator from 'simple-react-validator';
import { addComment, getCommentCategoryByTitle } from "redux/actions/comment-actions";
import { BsChatDots } from 'react-icons/bs';
import { IoMdCalendar } from 'react-icons/io';
import { baseUrl } from '../../../helper/ApiCall';

var moment = require("moment");
var cloneDeep = require('lodash.clonedeep');

class OrderHistory extends Component {
    constructor(props) {
        super(props)
        this.validatorCancel = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            filtered: '',
            // pageSize: 50,
            status: 'approved',
            departure_date: { value: '', type: 'date' },
            quickLinkModal: false,
            orderIndex: '',
            agencyOrders: {
                data: []
            },
            cancelComment: '',
            cancelCommentError: false,
        }
        this.inputChangeHandler = inputChangeHandler.bind(this);
        // this.dateChangeHandler = dateChangeHandler.bind(this);
        this.toggleModal = toggleModal.bind(this);
        this.confirmAlert = React.createRef();
    }

    filter = () => {
        this.confirmAlert.state.onFetchData(this.confirmAlert.state);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.departure_date.value !== prevState.departure_date.value) || (this.state.filtered !== prevState.filtered) || (this.state.status !== prevState.status)) {
            // this.filter();
            this.pagination.dataCall();
        }
    }


    openQuickLoginModal = (id) => {
        this.setState({
            quickLinkModal: true,
            orderIndex: id
        })
    }

    getAgencyDataWithStateValue = (data) => {
        const departure_date = this.state.departure_date;
        const status = this.state.status;
        const advancedFilters = {
            status,
            departure_date: { value: departure_date.value, type: departure_date.type }
        }
        return this.props.getAgencyOrdersCall(data.page, this.state.filtered, data.sort, data.pageSize, User.agency('id'), advancedFilters).then(
            res => {
                this.setState({
                    agencyOrders: res.data.pagination
                })
                return res;
            }
        );
    }

    dateChangeHandler = (value, field, dateType) => {

        const dateState = cloneDeep(this.state[field]);
        dateState['value'] = value !== null ? value : '';
        dateState['type'] = dateType;
        this.setState(prevState => ({
            ...prevState, [field]: dateState
        })
        );
    }

    dateElement = (name, value, rules, type, dateType) => {
        var selectedDate = this.state[name].value !== '' ? new Date(this.state[name].value) : '';

        if (!dateType)
            dateType = "date";
        var propTemp = {};

        if (dateType === 'year')
            propTemp = { maxDetail: "decade", yearPlaceholder: "Y", type: 'YYYY' };
        else if (dateType === 'monthYear')
            propTemp = { maxDetail: "year", monthPlaceholder: "M", yearPlaceholder: "Y", type: 'YYYY-MM' };
        else
            propTemp = { dayPlaceholder: "D", monthPlaceholder: "M", yearPlaceholder: "Y", type: 'YYYY-MM-DD' };

        if (!type)
            type = "text";

        return (

            <DatePicker
                className="form-control"
                value={dateType === this.state[name]['type'] ? selectedDate : ''}
                name={name}
                onChange={(event) => this.dateChangeHandler(event, name, dateType)}
                clearIcon={null}
                calendarIcon={null}
                {...propTemp}

            />
        );
    }

    // changeStatus = (orderId, status) => {
    //     if (this.state.cancelComment) {
    //         orderService.updateOrderStatusByTitle(orderId, status).then(response=>{
    //             this.props.getCommentCategoryByTitle(['Cancel']).then(
    //                 res => {
    //                     const data = {
    //                         comment: this.state.cancelComment,
    //                         comment_category_id: res.data.comment_category_id
    //                     }
    //                     this.props.addCommentCall(data, orderId)
    //                     this.setState({
    //                         cancelComment: ''
    //                     })
    //                 }
    //             )
    //             this.pagination.dataCall();
    //             this.confirmAlert.current.props.onClosePro();
    //         });
    //     }

    // }
    changeStatus = (orderId, status) => {
        if (this.state.cancelComment) {
            console.log("Comment", this.state.cancelComment);
            this.props.getCommentCategoryByTitle(['Cancel']).then(
                res => {
                    const data = {
                        comment: this.state.cancelComment,
                        comment_category_id: res.data.comment_category_id
                    }
                    this.props.addCommentCall(data, orderId).then(response => {
                        orderService.updateOrderStatusByTitle(orderId, status).then(response => {
                            this.confirmAlert.current.props.onClosePro();
                            this.pagination.dataCall();
                            this.setState({
                                cancelComment: ''
                            })
                            console.log('after', this.state.cancelComment);
                        })

                    })
                });
        }
    }


    confirmBox = (orderId, status, data) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalert
                        ref={this.confirmAlert}
                        header={`Cancel Order`}
                        description={`Are you sure you want to cancel this order?`}
                        onClosePro={onClose}
                        extraHtml={() => {
                            return (
                                <span>
                                    Group Name : {data.group_name} <br />
                                    Order # {data.barcode}
                                    <div className='form-group'>
                                        <textarea onChange={(e) => {
                                            this.setState({
                                                cancelComment: e.target.value
                                            })
                                        }} rows='5' placeholder='Make sure to add comment to cancel!' name="" className='form-control' id=""></textarea>

                                    </div>


                                </span>
                            );
                        }}
                        onNo={onClose}
                        onYes={() => { this.changeStatus(orderId, status) }}
                        yesLabel='Cancel Order'
                        noLabel='No'
                        doNotAutoCloseForYes
                    />
                )
            }
        })
    }

    downloadEditedImages = (orderId) => {
        const link = `${baseUrl}downloadEditedImages/${orderId}`;
        window.open(link);
    }
    render() {
        this.validatorCancel.purgeFields();
        const columns = [
            {
                Header: 'Order History',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Order#',
                        accessor: 'barcode',
                        width: 140,
                        Cell: ({ value, original }) => {
                            return <a rel='noreferrer noopener' target='_blank' className='blue_link' href={`/order-summary/${original.id}`}>{value}</a>
                        }
                    },
                    {
                        Header: 'Wedding Couple’s Name',
                        accessor: 'group_name',
                        width: 140,
                    },
                    {
                        Header: 'Destination',
                        accessor: 'destinations',
                        sortable: false,
                        width: 100,
                        className: 'text-left'
                    },
                    {
                        // Header: 'Videos/Travelers',
                        Header: () => (
                            <span>
                            # of Attendees
                            </span>
                        ),
                        accessor: 'numberOfVideos',
                        id: 'numberOfVideos',
                        width: 90,
                    },
                    {
                        Header: 'Start',
                        accessor: 'order_dates.departure_date.value',
                        id: 'orderDates.departure_date.value',
                        width: 80,
                    },
                    {
                        Header: 'Return',
                        accessor: 'order_dates.return_date.value',
                        id: 'orderDates.return_date.value',
                        width: 80,
                    },
                    {
                        Header: 'Active',
                        Cell: ({ original }) => {
                            return moment(original.order_dates.departure_date.value).diff(moment.now(), 'days') > 30
                                ?
                                original.status.title !== 'Cancelled' ? <React.Fragment><strong>Yes</strong>&nbsp;|<button onClick={() => this.confirmBox(original.id, 'cancelled', original)} className='btn-link' >Cancel</button></React.Fragment> : <strong>No</strong>
                                :
                                original.status.title !== 'Cancelled' ? <strong>Yes</strong> : <strong>No</strong>
                            // console.log('barcode', original.barcode )
                            // console.log('original', moment().diff(original.order_dates.departure_date.value, 'days') )
                            // return original.status.title !== 'Cancelled' ? 'Yes' : 'No'
                        },
                        sortable: false,
                        width: 60
                    },
                    {
                        Header: 'Status',
                        accessor: 'status.title',
                        Cell: ({ original }) => {
                            return TF.productionStatusFrontEnd(original.orderSettings, original.status.title)
                        },
                        id: 'Status.title',
                        className: 'text-center',
                        width: 80,
                    },
                    {
                        Header: 'Package',
                        accessor: 'status.title',
                        Cell: ({ original }) => {
                            return (
                                // <div>
                                console.log("Package", original.program_type.title.includes('Mozaix only')),
                                    <span>{original.program_type.title.includes('Mozaix Only') ?  "Mozaix Only" : "Video/Mozaix" }</span>
                                // </div>
                            )
                        },
                        id: 'Status.title',
                        className: 'text-center',
                        width: 100,
                    },
                    {
                        Header: 'Links',
                        accessor: 'link',
                        width: 190,
                        className: 'flex-wrap',
                        Cell: ({ original, index }) => {
                            let showPhotoLinks = false, status;
                            const { order_resources, orderSettings, order_dates } = original;
                            if (order_resources.length > 0) {
                                showPhotoLinks = true;
                            }
                            if ('receive_date' in order_dates && order_dates.receive_date.value !== null) {
                                status = {
                                    title: 'In Production',
                                    icon: s3StaticPath('img/status_completed.png')
                                };
                                showPhotoLinks = true;
                            } else {
                                status = {
                                    title: 'Not Yet Received',
                                    icon: s3StaticPath('img/status_completed.png')
                                };
                            }
                            if (Number(orderSettings.finalize_by_editor.value)) {
                                status = {
                                    title: 'Photo Edited',
                                    icon: s3StaticPath('img/status_completed.png')
                                };
                                showPhotoLinks = true;
                            }
                            if (Number(orderSettings.finalize_by_qa.value)) {
                                status = {
                                    title: 'Completed',
                                    icon: s3StaticPath('img/status_completed.png')
                                };
                                showPhotoLinks = true;
                            }

                            // let resource = {
                            //     file_title: "file_title",
                            //     file_path: "file_path"
                            // }
                            const orderPriority = ["Highlight Video", "Full Video", "Mozaix File"];

                            const sortedResources = order_resources.sort((a, b) => {
                                return orderPriority.indexOf(a.resource_type.title) - orderPriority.indexOf(b.resource_type.title);
                            });
                            return (
                                showPhotoLinks && (Number(orderSettings.finalize_by_qa.value) && original.status.title !== 'Cancelled') ?
                                    <div className='inlineFlexElem alignCenter orderHistoryActions'>
                                        <OverlayTrigger placement="left"
                                            overlay={<Tooltip id="tooltip">Photo Gallery</Tooltip>}>
                                            <Link style={{ fontSize: '20px' }} target='_blank' className='blue_link_gallery' to={`image-gallery/${original.id}`}><i className="fa fa-image" aria-hidden="true" /></Link>
                                        </OverlayTrigger>
                                        {
                                            /*
                                        <OverlayTrigger placement="left"
                                            overlay={<Tooltip id="tooltip">{status.title}</Tooltip>}>
                                            <span><img src={status.icon} alt={status.title} /></span>
                                        </OverlayTrigger>
                                        */
                                        }
                                        <OverlayTrigger placement="left"
                                            overlay={<Tooltip id="tooltipOne">Quick Login</Tooltip>}>
                                            <span onClick={() => { this.openQuickLoginModal(index) }}><img src={s3StaticPath('img/quick_login.png')} alt="" /></span>
                                        </OverlayTrigger>

                                        {
                                            sortedResources.map(resource => {
                                                if (resource.resource_type.title !== "Original Mosaic") {
                                                    return (
                                                        <OverlayTrigger key={resource.id} placement="left"
                                                            overlay={<Tooltip id={`tooltip${resource.id}`} key={resource.id}>{resource.resource_type.title}</Tooltip>}>
                                                            <span>
                                                                <DownloadFile key={resource.id} extraClass={
                                                                    resource.resource_type.title === "Full Video"
                                                                        ? " downloadVideo"
                                                                        : resource.resource_type.title === "Highlight Video"
                                                                            ? " downloadHighlightVideo"
                                                                            : resource.resource_type.title === "Mozaix File"
                                                                                ? " downloadIconHeight"
                                                                                : ""
                                                                } files={{
                                                                    'file_title': resource.file_title,
                                                                    'file_path': resource.file_path
                                                                }} title={""} />
                                                            </span>
                                                        </OverlayTrigger>
                                                    )
                                                }
                                            })
                                        }

                                        <OverlayTrigger placement="left"
                                        overlay={<Tooltip id="tooltip">All Images</Tooltip>}>

                                            <button className='orderDetailsAction downloadIcon downloadImage' onClick={() => {this.downloadEditedImages(original.id)}}></button>
                                        </OverlayTrigger>
                                    </div>
                                    :
                                    ''
                            );
                        }
                    },
                    {
                        Header: "Actions",
                        accessor: "status.title",
                        className: 'text-left',
                        width: 70,
                        Cell: ({ original }) => {
                            return <div>
                                <span style={{ paddingRight: '10px' }}>
                                    {
                                        original && original.agency && original.agency.accounts && original.agency.accounts.length > 0 &&
                                        !!+original.agency.accounts[0].has_itinerary &&
                                        <OverlayTrigger placement="left" overlay={<Tooltip id={original.id}>{'View Itinerary'}</Tooltip>}>
                                            <Link to={`/create-itinerary/${original.agency.accounts[0].id}/${original.id}`}>
                                                <IoMdCalendar size={25} />
                                            </Link>
                                        </OverlayTrigger>
                                    }
                                </span>
                                <span>
                                    {
                                        original && original.agency && original.agency.accounts && original.agency.accounts.length > 0 &&
                                        !!+original.agency.accounts[0].can_view_chat &&
                                        <OverlayTrigger placement="left" overlay={<Tooltip id={original.id}>{'View Chat'}</Tooltip>}>
                                            <Link to={`/chat/${original.id}`}><BsChatDots size={20} /></Link>
                                        </OverlayTrigger>
                                    }
                                </span>
                            </div >
                        },
                    },
                    {
                        Header: 'Print',
                        sortable: false,
                        Cell: ({ original }) => {
                            return <Link style={{ fontSize: '20px' }} target='_blank' className='blue_link' to={`print-order-summary/${original.id}`}><i className="fa fa-print" aria-hidden="true" /></Link>
                        },
                        show: false,
                    }
                ]
            }
        ]
        const { agencyOrders } = this.state;
        const {
            filtered,
            departure_date } = this.state;
        return (
            <div className='container mt-md'>
                <img src={User.agencyLogo() ? User.agencyLogo() : s3StaticPath('img/company_logo_big.png')} alt="Logo name" style={{ maxHeight: "127px" }} />
                <h2 className="panel_heading userLogin_heading">Your Order History</h2>
                <div className='row mb-xlg mt-xlg'>
                    <div className="col-md-12">
                        <div className="form_tile">
                            <div className="form_tile_heading">
                                <span>Filter Order History</span>
                            </div>

                            <div className="form_tile_content">
                                <div className="flexElem respFlex filterBox alignCenter form_tile_row">
                                    <div className="inlineFlexElem noBreakLabel alignCenter">
                                        <div><label htmlFor="">Wedding Couple’s Name:</label></div>
                                        <div><input type="text" name='filtered' style={{ width: '14vw' }} onChange={this.inputChangeHandler} value={filtered} placeholder="Group Name" className="form_tile_input" /></div>
                                    </div>
                                    <div className="inlineFlexElem noBreakLabel alignCenter">
                                        <div><label htmlFor="">Start Date:</label></div>

                                        <div className="flexElem datesContainer">
                                            <div className='col-md-3'>
                                                {
                                                    this.dateElement('departure_date', departure_date.value !== '' && moment(departure_date.value, 'YYYY'), [], 'date', 'year')
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                {
                                                    this.dateElement('departure_date', departure_date.value !== '' && moment(departure_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
                                                }
                                            </div>

                                            <div className='col-md-5'>
                                                {
                                                    this.dateElement('departure_date', departure_date.value !== '' && moment(departure_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
                                                }
                                            </div>
                                        </div>

                                        {
                                            /*
                                        <DatePicker
                                            autoComplete='off'
                                            placeholderText='MM/DD/YYYY'
                                            className="form_tile_input date_input block"
                                            onChange={(event) => this.dateChangeHandler(event, 'departure_date')}
                                            selected={departure_date}
                                            name="departure_date" />
                                            */
                                        }
                                    </div>
                                    <div className="inlineFlexElem noBreakLabel alignCenter">
                                        <div><label htmlFor="">Sort By:</label></div>
                                        <div className="flexElem block spaceBetween" style={{ flexWrap: 'wrap' }}>
                                            <div className="customChk">
                                                <input onChange={this.inputChangeHandler} value='all' type="radio" id="all" name="status" />
                                                <label htmlFor="all">All</label>
                                            </div>

                                            <div className="customChk">
                                                <input onChange={this.inputChangeHandler} defaultChecked value='approved' type="radio" id="active" name="status" />
                                                <label htmlFor="active">Active</label>
                                            </div>

                                            <div className="customChk">
                                                <input onChange={this.inputChangeHandler} value='cancelled' type="radio" id="cancelled" name="status" />
                                                <label htmlFor="cancelled">Cancelled</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="inlineFlexElem alignCenter">
                                        <button onClick={this.filter} className="themeBtn_12 block purple_gradient hover_dark">Filter</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-xlg'>
                    <div className='col-md-122 noMarginTable frontEndTable noBorderTable'>
                        <Pagination
                            ref={(p) => this.pagination = p}
                            columns={columns}
                            getDataCall={this.getAgencyDataWithStateValue}
                            minRows={5}
                            pageSize={25}
                        //style={{
                        //height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                        //}}
                        // filterView={false}
                        // upperContent={}
                        // downloadData = {false}
                        // lowerContent = {null}
                        // filterPlaceHolder = {null}
                        //getRowProps = { this.getRowProps }
                        />
                    </div>
                </div>
                <ThemeModal title='Quick Link Login' modalClass='frontEndModal' show={this.state.quickLinkModal} hide={() => { this.toggleModal('quickLinkModal') }} >
                    <p>Quick Login Link - {this.state.orderIndex !== '' && agencyOrders.data[this.state.orderIndex].group_name}</p>
                    {
                        this.state.orderIndex !== '' &&
                        <a className='blue_link' href={returnDynamicURL(agencyOrders.data[this.state.orderIndex].accounts ? agencyOrders.data[this.state.orderIndex].accounts[0].domain_slug : null, agencyOrders.data[this.state.orderIndex].username, agencyOrders.data[this.state.orderIndex].password
                        )}>{returnDynamicURL(agencyOrders.data[this.state.orderIndex].accounts ? agencyOrders.data[this.state.orderIndex].accounts[0].domain_slug : null, agencyOrders.data[this.state.orderIndex].username, agencyOrders.data[this.state.orderIndex].password
                        )}</a>
                    }
                    <p>You can use the above Quick Login Link to provide to potential customers to give them an example of the Wedding TripKit Photo Gallery and finished videos.</p>
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {
        getAgencyOrdersCall: (page, filter, sort, pageSize, agencyId, advancedFilters) => { return dispatch(getAgencyOrders(page, filter, sort, pageSize, agencyId, advancedFilters)) },
        getCommentCategoryByTitle: (titles) => dispatch(getCommentCategoryByTitle(titles)),
        addCommentCall: (data, modelId) => { return dispatch(addComment(data, modelId)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(OrderHistory))
