import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    deleteImage,
    toggleImageStatus,
    toggleImageKeepsake,
    rotateImage
} from 'redux/actions/imageActions';
import User from 'helper/User';
import {Roles} from 'constants/Roles';
import {getSingleImage, getOrderUploadInfo} from 'redux/actions/order-actions';
import {confirmAlert} from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class Image extends Component {

    constructor(props) {
        super(props)
        this.state = {
            status: this.props.image.approved_status,
            path: this.props.image.resized_image_path.replace('SIZE', this.props.imageSize),
            tries: 0,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.singleImage.image_format !== prevProps.singleImage.image_format && this.props.image.id === this.props.singleImage.id) {
            this.setState({
                status: this.props.singleImage.approved_status
            });
        }
        if (this.props.image.approved_status !== prevProps.image.approved_status) {
            this.setState({
                status: this.props.image.approved_status
            })
        }
        if (this.props.imageSize !== prevProps.imageSize) {
            this.setState({
                path: this.props.image.resized_image_path.replace('SIZE', this.props.imageSize)
            })
        }
        // if (this.props.image.updated_at !== prevProps.image.updated_at) {
        //     const image = this.props.image;
        //     this.setState({
        //         path: image.file_path + image.file_title + "." + image.image_format.extension + '?updated_at=' + image.updated_at
        //     })
        // }
    }

    deleteImage = () => {
        this.props.deleteImageCall(this.props.image.id);
    }

    confirmImageDelete = () => {
        confirmAlert({
            customUI: ({onClose}) => <ConfirmAlertForDelete typeName='Image' onClosePro={onClose}
                                                            deleteType={this.deleteImage}/>
        })
    }

    toggleImagesStatus = (inStatus) => {
        const {id} = this.props.image
        const images = [id];
        console.log("inStatus", inStatus)
        let status = 1 - inStatus;
        console.log("status", status)
        this.props.toggleImageStatusCall({images, status}).then(
            response => {
                this.props.getSingleImageCall(id);
                this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
            }
        );
    }

    toggleImageKeepsake = (inStatus) => {
        const {id} = this.props.image
        const images = [id];
        console.log("inStatus", inStatus)
        let status = 1 - inStatus;
        console.log("status", status)
        this.props.toggleImageKeepsakeCall({images, status}).then(
            response => {
                this.props.getSingleImageCall(id);
                this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
            }
        );
    }

    rotateImage = (direction) => {
        this.props.rotateImageCall(this.props.singleImage.id, direction);
    }

    getImagePath = () => {
        if (Number(this.props.orderUploadInfo.finalize_by_qa.value) === 1) {
            const path = this.state.path.replace(/jpg$/, 'JPG');
            this.setState({
                path
            })
        } else {
            setTimeout(() => {
                let newPath = this.state.path + "?updated_at=" + this.props.image.updated_at
                let tries = this.state.tries;
                // if (tries % 2 === 0) {
                //     newPath = newPath.replace(/\.jpg/, '\.JPG');
                // }
                if (newPath.match(/\.jpg/)) {
                    newPath = newPath.replace(/\.jpg/, '.JPG');
                } else {
                    newPath = newPath.replace(/\.JPG/, '.jpg');
                }
                tries++;
                this.setState({
                        path: newPath,
                        tries: tries
                    }
                )
            }, 1000)
        }
    }

    render() {
        const {image, orderUploadInfo} = this.props;
        const {status} = this.state;
        return (
            <div className={this.props.selected === 'selected' ? ' selectedDiv' : ''}>
                <div className={this.state.status ? "uploadedImageSize" : "uploadedImageSize notApproved"} style={{height:'100%'}}>
                    {
                        User.hasRole(Roles.PHOTO_ADMIN) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                        <button onClick={this.confirmImageDelete} className="delete_img delete_icon hover_dark">
                            &nbsp;
                        </button>
                    }
                    {
                        (Number(orderUploadInfo.finalize_by_qa.value) !== 1 && User.hasRole(Roles.PHOTO_ADMIN)) &&

                        <OverlayTrigger placement="right" overlay={
                            <Tooltip id="tooltip">You can use this to select this photo so you can drag and drop
                                multiple photos at once. </Tooltip>
                        }>
                            <button type="button" onClick={() => {
                                this.props.selectImageHandler(this.props.index)
                            }}
                                    className={'delete_img hover_dark multiSelect' + (this.props.selected === 'selected' ? ' imgSelected' : ' multi_select')}>
                                &nbsp;
                            </button>
                        </OverlayTrigger>

                    }
                    <button onClick={() => {
                        this.props.openPopup(this.props.index, true)
                    }} style={{right: '50px'}} className='delete_img zoom_icon hover_dark'>
                    </button>
                    <img
                        // src={image.file_path + image.image_versions.version_type + '/' + image.file_title + "." + image.image_format.extension + '?updated_at=' + image.updated_at}
                        // src={image.resized_image_path.replace('SIZE', imageSize)}
                        src={this.state.path + "?updated_at=" + image.updated_at}
                        onError={this.getImagePath}
                        alt="Text"
                        onClick={(Number(orderUploadInfo.finalize_by_qa.value) !== 1 && User.hasRole(Roles.PHOTO_ADMIN)) ? () => {
                            this.props.selectImageHandler(this.props.index)
                        } : null}
                        // onClick={() => { this.props.selectImageHandler(this.props.index) } }
                        // onClick={() => { this.props.openPopup(this.props.index, true) }}
                    />

                    {
                        User.hasRole(Roles.PHOTO_ADMIN) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                        Number(orderUploadInfo.auto_approve_photos.value) === 0 &&
                        <div
                            className={"approvalChk hover_dark" + (this.state.status ? ' gray_gradient' : ' purple_blue_gradient')}>
                            <div className="customChk" style={{top:'auto', bottom:'0.5rem',right:'0.5rem'}}>
                                <button onClick={(e) => {
                                    this.toggleImagesStatus(status)
                                }} className={this.state.status ? "checkedBtn checkBtn text_14" : "checkBtn text_14"}
                                        htmlFor={'image_' + image.id}>Approve
                                </button>
                            </div>
                        </div>
                    }
                    {
                        User.hasRole(Roles.PHOTO_ADMIN) && 
                        <div
                            className={"approvalChk hover_dark" + (image.is_keepsake ? ' gray_gradient' : ' purple_blue_gradient')} 
                            // style={{left: '0px', right: 'auto'}}
                            >
                            <div className="customChk" style={{top:'auto', bottom:'0.5rem',right:'0.5rem'}}>
                                <button onClick={(e) => {
                                    this.toggleImageKeepsake(image.is_keepsake)
                                }} className={image.is_keepsake ? "checkedBtn checkBtn text_14" : "checkBtn text_14"}
                                        htmlFor={'image_' + image.id}>keepsake
                                </button>
                            </div>
                        </div>
                    }

                </div>
                {
                    this.props.showData &&
                    <React.Fragment>
                        <div className="galleryInfo p-sm">
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip id={image.id}>
                                    Uploaded By
                                </Tooltip>
                            }>
                                <span className="text_16">{image.uploader_name}</span>
                            </OverlayTrigger>

                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip id={image.id}>
                                    Photo Name
                                </Tooltip>
                            }>
                                <span
                                    className="text_14 galleryId">{Number(orderUploadInfo.finalize_by_qa.value) === 1 ? image.image_versions.file_label : image.id}</span>
                            </OverlayTrigger>
                        </div>

                        {
                            image.date_taken && User.hasRole(Roles.PHOTO_ADMIN) &&
                            <div className="pl-sm pr-sm mb-md text_left_center_991">
                                <OverlayTrigger placement="bottom" overlay={
                                    <Tooltip id={image.id}>
                                        Date
                                    </Tooltip>
                                }>
                                    <span className="text_14 text-muted dark">{image.date_taken}</span>
                                </OverlayTrigger>
                            </div>
                        }
                    </React.Fragment>
                }
                {
                    this.props.editedPhotoGallery &&
                    <React.Fragment>
                        <div className="photoGallery p-sm">
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip id={image.id}>
                                    Photo Name
                                </Tooltip>
                            }>
                                <p className="text_16">
                                    {image.image_versions.file_label}
                                </p>
                            </OverlayTrigger>

                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip id={image.id}>
                                    Uploaded by
                                </Tooltip>
                            }>
                                <span className="text_14 galleryId mt-1">
                                    {image.is_web_image ? "web" : "app"}
                                </span>
                            </OverlayTrigger>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {singleImage, orderUploadInfo} = state.frontEndUserReducer;
    return {
        singleImage,
        orderUploadInfo
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteImageCall: (id) => {
            dispatch(deleteImage(id))
        },
        toggleImageStatusCall: (images) => {
            return dispatch(toggleImageStatus(images))
        },
        toggleImageKeepsakeCall: (images) => {
            return dispatch(toggleImageKeepsake(images))
        },
        getSingleImageCall: (imageId) => {
            dispatch(getSingleImage(imageId))
        },
        rotateImageCall: (imageId, direction) => {
            dispatch(rotateImage(imageId, direction))
        },
        getOrderUploadInfoCall: (orderId) => dispatch(getOrderUploadInfo(orderId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Image)
