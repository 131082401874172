import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Dashboard} from 'layouts/Frontend/Dashboard/Dashboard';
import {Link} from 'react-router-dom'
import FileUpload from 'components/Upload/FileUpload';
import ImagePreview from 'components/Upload/ImagePreview';
import User from 'helper/User';
import { PulseLoader } from "react-spinners";
import {isMobile} from 'react-device-detect'
import {
    storeOrderImages,
    getOrderUploadInfo,
    updateOrderUploadCutOffDate,
    orderImages,
    updateOrderSettings,
    getOrderImagesPath,
    storeOrderImagesInDbByFrontEnd
} from 'redux/actions/order-actions';
import OrderUploadInfo from 'components/FrontEnd/Uploader/OrderUploadInfo';
import Validator from 'simple-react-validator'
import {OverlayTrigger, Tooltip, Popover, Row , Col} from 'react-bootstrap';
import {toggleModal, s3StaticPath, uuid} from 'helper/helperFunctions';

import PhotoGallery from 'components/FrontEnd/PhotoGallery/PhotoGallery';
import {Transition} from 'react-transition-group';
import $ from 'jquery';
import {FiChevronDown} from 'react-icons/fi';
import {Roles} from 'constants/Roles';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import {Status} from 'constants/Status';
import {getOrdersResourcesByRole} from 'redux/actions/agency-resource-actions';
import {getAllowReorder} from 'redux/actions/order-actions';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import {alertActions} from 'redux/actions/alert-actions';
import Axios from 'axios';
import {uploaderErrors} from 'components/FrontEnd/Uploader/uploaderErrors';
import {photoGalleryErrors} from 'components/FrontEnd/Uploader/photoGalleryErrors';
import Documents from 'components/FrontEnd/Order/OrderForm/Documents';
import { toast } from 'react-toastify';
import {BiLoaderAlt} from 'react-icons/bi';
import EXIF from 'exif-js';
import Lightbox from 'react-image-lightbox';
import LoadingOverlay from "react-loading-overlay";
import 'react-image-lightbox/style.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import exifr from 'exifr';
import { reportService } from '../../../services/report';
import { baseUrl } from '../../../helper/ApiCall';
import OrderComments from 'components/Admin/Order/OrderComments';
import VideoGallery from 'components/FrontEnd/VideoGallery/VideoGallery';
import Videos from 'components/FrontEnd/VideoGallery/Videos';
var Modal = require('react-bootstrap-modal');
// import MyImage from 'components/Upload/MyImage';
var cloneDeep = require('lodash.clonedeep');
var moment = require("moment");


class Uploader extends Component {

    constructor(props) {
        super(props)
        this.videoRef = React.createRef();
        this.photoUploaderKey = 'photoUploader' + User.order('id');
        this.state = {
            files: [],
            photoUploader: {
                firstName: '',
                lastName: ''
            },
            showUploader: true,
            showDoc: true,
            submitVideoProductionModal: false,
            reorderKeepsakeModal: false,
            production_submitted: false,
            showHighlightVideo: false,
            uploadedFiles: 0,
            filesToUpload: 0,
            filesNotUploaded: 0,
            activeTabNo: 1,
            toggleUploader: true,
            toggleUploaderDoc: true,
            submitOrderDocuments: false,
            perPage: '',
            imageData: [],
            total_photo_limit: '',
            isOpen: false,
            showLoader: false,
            is_ucd_after_return_date: false,
            galleryTabsNo: 1,
            videoGallerySection: false,
            photoGallerySection: true,

        }
        this.toggleModal = toggleModal.bind(this);
        this.props.getAllowReorderCall(User.getProperty('order.id'));
        this.validator = new Validator();
        this.uploader = React.createRef();
        // this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
        this.props.getOrderVideos(User.getProperty('order.id'));
        this.props.getOrderImagesPathCall(User.getProperty('order.id'), 'original');
    }

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
      };

    setResourceFile = (key, value) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        value.axiosToken = source;
        this.setState((prevState) => {
            return {
                files: [
                    ...prevState.files,
                    value
                ],
                filesToUpload: prevState.filesToUpload + 1
            }
        });

    }

    registerPhotoUploader = () => {
        if (this.validator.allValid()) {
            const photoUploader = this.state.photoUploader;
            localStorage.setItem(this.photoUploaderKey, JSON.stringify(photoUploader));
            window.location.reload();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    inputChange = (e) => {
        const photoUploader = this.state.photoUploader;
        this.setState({
            photoUploader: {
                ...photoUploader,
                [e.target.name]: e.target.value
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {

       
    }


    fetchExifData1 = (file, u_id) => {
        let ext =  file.name.split('.').pop();

        return new Promise((resolve, reject) => {
            var Data = {};
            try {
                console.log('EXIF.getData file.name', file.name);
                EXIF.getData(file, function() {
                    const exifData = EXIF.pretty(this);
                    if (exifData) {
                        console.log('if (exifData) ');
                        try {
                            Data =  {
                                file_size : file.size / 1024,
                                file_title : file.name,
                                file_type : file.type,
                                original_file_name : u_id + '.' + ext,
                                exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
                                use_in_highlight_video  : 0,
                                exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
                                DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
                                ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
                                exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
                                exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
                                exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
                                exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
                                height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
                                width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
                            };
                        }
                        catch(er) {
                            Data = {
                                file_size : file.size / 1024,
                                file_title : file.name,
                                file_type : file.type ,
                                original_file_name : u_id + '.' + ext
                            };
                            console.log('EXIF.getTag read error', file.name, er)
                        }

                    } else {
                        console.log('else (exifData) ');
                        Data = {
                            file_size : file.size / 1024,
                            file_title : file.name,
                            file_type : file.type ,
                            original_file_name : u_id + '.' + ext
                        };
                    }
                    resolve(Data);
                });
            }
            catch (error) {
                Data = {
                    file_size : file.size / 1024,
                    file_title : file.name,
                    file_type : file.type ,
                    original_file_name : u_id + '.' + ext
                };

                console.log('exif data read error', file.name, error)

                resolve(Data);
            }
        });
    }
    fetchExifData = async (file, u_id) => {
        let ext =  file.name.split('.').pop();

        return new Promise(async(resolve, reject) => {
            var Data = {};
            try {
                console.log('EXIF.getData file.name', file.name);

                    const exifData = await exifr.parse(file, true);
                    if (exifData) {
                        try {
                            Data =  {
                                file_size : file.size / 1024,
                                file_title : file.name,
                                file_type : file.type,
                                original_file_name : u_id + '.' + ext,
                                exif_orientation : exifData.Orientation ? exifData.Orientation : null,
                                use_in_highlight_video  : 0,
                                exif_date_taken : exifData.CreateDate ? moment(exifData.CreateDate).format("YYYY-MM-DD HH:mm:ss") : null,
                                DateTimeOriginal : exifData.DateTimeOriginal ? exifData.DateTimeOriginal : null,
                                ModifyDate : exifData.ModifyDate ? exifData.ModifyDate : null,
                                exif_manufacturer : exifData.Make ? exifData.Make : null,
                                exif_camera_model : exifData.Model ? exifData.Model : null,
                                exif_long   : exifData.GPSLongitude ? exifData.GPSLongitude : null,
                                exif_lat : exifData.GPSLatitude ? exifData.GPSLatitude : null,
                                height : exifData.ExifImageHeight ? exifData.ExifImageHeight : null,
                                width  : exifData.ExifImageWidth ? exifData.ExifImageWidth : null,
                            };
                        }
                        catch(er) {
                            Data = {
                                file_size : file.size / 1024,
                                file_title : file.name,
                                file_type : file.type ,
                                original_file_name : u_id + '.' + ext
                            };
                            console.log('EXIF.getTag read error', file.name, er)
                        }

                    } else {
                        Data = {
                            file_size : file.size / 1024,
                            file_title : file.name,
                            file_type : file.type ,
                            original_file_name : u_id + '.' + ext
                        };
                    }
                    resolve(Data);
            }
            catch (error) {
                Data = {
                    file_size : file.size / 1024,
                    file_title : file.name,
                    file_type : file.type ,
                    original_file_name : u_id + '.' + ext
                };

                console.log('exif data read error', file.name, error)

                resolve(Data);
            }
        });
    }

    getPresignedUrls = async (currentImageName, fileType) => {
        const { orderImagePath } = this.props;
        let AdminUser = JSON.parse(localStorage.getItem('user'));
        const files = [
            {
                name: orderImagePath + '/' + currentImageName, // Concatenates file name with orderImagePath
                orgfile_name: currentImageName, // Concatenates file name with orderImagePath
                type: fileType,
            }
        ]
        const response = await fetch(`${baseUrl}generate-presigned-urls`, {
            method: 'POST',
            body: JSON.stringify({
                files
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AdminUser.token  
            },
        });
        return response.json();
    };

    uploadImageToS3 = async(file, currentImageName, urls) => {
        this.toastIdImg = null;
			this.toastIdImg =  toast(<React.Fragment>{file.name} is uploading. Please wait!  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
				type: toast.TYPE.INFO,
				position: "bottom-right",
                autoClose: false
			});

        return new Promise(async(resolve, reject) => {
            if(file != undefined) {
                const fileUrl = urls[0].url;
                const response = await fetch(fileUrl, {
                method: 'PUT',
                body: file,
                headers: { 
                    'Content-Type': file.type,
                    },
                });
                if (response.ok) {
                    // Call the success callback with the response details
                    resolve(response);
                    toast.dismiss(this.toastIdImg);
                }
            }
            else {
                reject(currentImageName,' file did not uploaded to S3, please try again.');
            }
        });
    }

    submitToDB = (file, exif_data) => {
        // const orderImagePath = this.props.orderImagePath;
        return new Promise((resolve, reject) => {

                    let img_custom_data = [exif_data];
                    let postData = {images: img_custom_data , uploaded_by_first_name: this.state.photoUploader.firstName, uploaded_by_last_name: this.state.photoUploader.lastName} ;
                    const DbResponse = this.props.storeOrderImagesInDbByFrontEndCall(postData, User.order('id')).then(response=>{
                    const newFiles = this.state.files.filter(item => {
                                            return item.name !== file.name;
                                        });
                    this.props.imagesUploadedMessage(`${file.name} Image Uploaded Successfully`);
                            const uploaded = this.state.uploadedFiles;
                            this.setState({
                                files: newFiles,
                                uploadedFiles: uploaded + 1
                            }, () => {
                                if (this.state.uploadedFiles === this.state.filesToUpload) {
                                    this.setState({
                                        filesToUpload: 0,
                                        uploadedFiles: 0
                                    }, () => {
                                        this.props.orderImagesCall(User.order('id'), 'original', 'Uploaded-images', 1, '', '', this.state.perPage, User.user.role.name);
                                        this.props.getOrderUploadInfoCall(User.order('id'));
                                        //    imagessuccesscall
                                    })
                                }

                            });

                    },
                    error => {
                        // console.log(error,"reeeer");
                        Object.keys(error).map(x => {
                            // console.log("errrorrrr log", error[x][0]);
                            file['message'] = error[x];
                            this.setState({
                                total_photo_limit: error[x][0]
                            },()=>{
                                // console.log("state successfully update", this.state.total_photo_limit);
                            })
                            // console.log("error message//////",file['message'] );
                        });
                    }
                    );



                resolve(DbResponse);

        });
    }



    wait = ms => {return new Promise(resolve => setTimeout(resolve, ms))}

    uploadSubmitHandler = async (e) => {
        e.preventDefault();
        const files = [...this.state.files];
        // const orderImagePath = this.props.orderImagePath;
        // for of sync
        // var iteration_delay = 500;//delay in miliseconds
        for (const file of files)
        {
           
                let u_id = uuid().toString();
                var currentImageName = '';
                let ext =  file.name.split('.').pop();
                console.log("ext....", ext);
                if (ext.toLowerCase() !== 'jpg' && ext.toLowerCase() !== 'jpeg'){
                    console.log("if part exwcute");
                    const fd = new FormData();
                    fd.append('images[]', file);
                    fd.append('photoUploaderFirstName', this.state.photoUploader.firstName);
                    fd.append('photoUploaderLastName', this.state.photoUploader.lastName);
                    fd.append('file_name', file.name);
                    try {
                        const response = await this.props.storeImagesCall(fd, User.order('id'), file.axiosToken)
                        const newFiles = this.state.files.filter(item => {
                            return item.name !== file.name;
                        });
                        this.props.imagesUploadedMessage(`${file.name} Image Uploaded Successfully`);
                        const uploaded = this.state.uploadedFiles;
                        this.setState({
                            files: newFiles,
                            uploadedFiles: uploaded + 1
                        }, () => {
                            if (this.state.uploadedFiles === this.state.filesToUpload) {
                                this.setState({
                                    filesToUpload: 0,
                                    uploadedFiles: 0
                                }, () => {
                                    this.props.orderImagesCall(User.order('id'), 'original', 'Uploaded-images', 1, '', '', this.state.perPage, User.user.role.name);
                                    this.props.getOrderUploadInfoCall(User.order('id'));
                                    //    imagessuccesscall
                                })
                            }

                        });
                    } catch (error) {

                            console.log(error,"reeeer");
                            Object.keys(error).map(x => {
                                // console.log("errrorrrr log", error[x][0]);
                                file['message'] = error[x];
                                this.setState({
                                    total_photo_limit: error[x][0]
                                },()=>{
                                    // console.log("state successfully update", this.state.total_photo_limit);
                                })
                                // console.log("error message//////",file['message'] );
                            });

                    }

                }else{
                    console.log("else part exwcute");
                    currentImageName = u_id + '.' + ext;

                    let exif_data = '';
                    try{
                        exif_data = await this.fetchExifData(file, u_id);
                        const { urls } = await this.getPresignedUrls(currentImageName, file.type);
                        const res = await this.uploadImageToS3(file, currentImageName, urls)

                        const submitToDBres = await this.submitToDB(file, exif_data)

                    }
                    catch(err) {
                        console.log('exception err', err);
                        // this.props.imagesFailedMessage(`${file.name} upload failed,File must not be less than 100kb`, )
                    }
                }


                const total_photo_limit = this.state.total_photo_limit;

                if (total_photo_limit != '' && typeof(total_photo_limit) === 'string' && total_photo_limit.match('You have reached max upload limit') )
                {
                    console.log('You have reached max upload limit', total_photo_limit);
                    break;
                } else {
                    continue;
                }

        }
    }

    removeFile = (key, index) => {
        const files = [...this.state.files]
        files.splice(index, 1);
        this.setState(prevState => (
            {
                files: files,
                filesToUpload: prevState.filesToUpload - 1
            }
        ), () => {
            if (this.state.filesToUpload === this.state.uploadedFiles) {
                this.setState({
                    filesToUpload: 0,
                    uploadedFiles: 0
                })
            }
        });
    }

    componentDidMount() {
        if (localStorage.getItem(this.photoUploaderKey) !== null) {
            this.setState({
                photoUploader: JSON.parse(localStorage.getItem(this.photoUploaderKey))
            })
        }
        this.setState({
            originalHeight: $('.uploader_box').outerHeight()
        })
        let that = this;
        setTimeout(function () {
            that.toggleUploaderDoc()
        }, 1000)
    }

    toggleUploader = (e, value) => {
        $('.uploader_box_contains').stop(true, true).slideToggle("slow", () => {
            this.setState({
                toggleUploader: !this.state.toggleUploader
            });

           
        });
    }

    toggleUploaderDoc = () => {
        $('.uploader_box_doc_containss').stop(true, true).slideToggle("slow", () => {
            this.setState({
                toggleUploaderDoc: !this.state.toggleUploaderDoc
            });

            let hash = window.location.hash;
            if (hash && this.state.toggleUploaderDoc) {
                let topOffset = $(hash).offset().top;
                if (hash !== "#top")
                    $("html,body").animate({scrollTop: topOffset}, 100);
            }
        });
    }
    toggleTabs = (e) => {
        e.preventDefault();
        $(".tabsContentUpdated").stop(true, true).slideToggle();
        $(".tabsCollapser").toggleClass("closed");
    }
    toggleDocumentTabs = (e) => {
            e.preventDefault();
            $(".uploader_box_doc_contains").stop(true, true).slideToggle();
            $(".tabsCollapserDocuments").toggleClass("closed");
        }

    uploadDateHandler = (days) => {
        this.props.updateOrderUploadCutOffDateCall(User.getProperty('order.id'), {
            value: this.props.orderUploadInfo.upload_cut_off_date,
            days: days
        }).then(
            res => {
                this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
            }
        )
    }

    submitForVideoProduction = () => {
        this.props.updateOrderSettingsCall(User.getProperty('order.id'), this.props.orderUploadInfo.photos_submitted_for_video_production.value, 'photos_submitted_for_video_production').then(
            res => {
                this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
                this.setState({
                    production_submitted: true
                })
            }
        );
    }

    cancelUpload = () => {
        this.setState({
            files: [],
            filesToUpload: 0
        })
    }

    changeTab = (tab) => {
        this.setState({
            activeTabNo: tab
        });
        // this.state.activeTabNo != 1 ? this.setState({ activeTabNo: 1 }) : null
    }
    changeGalleryTab = (tab) => {
        this.setState({
            galleryTabsNo: tab
        });
    }

    cancelAxiosUpload = (file) => {
        file.axiosToken.cancel();
        const newFiles = this.state.files.filter(item => {
            return item.name !== file.name;
        });
        const filesToUpload = this.state.filesToUpload;
        this.setState({
            files: newFiles,
            filesToUpload: filesToUpload - 1
        })
    }

    downloadImage = (orderResources) => {
        // const downloadUrl = file.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.fullIndex]) + '?updated_at=' + file.updated_at;
        const downloadUrl = orderResources['20x30_mosaic_preview'].file_path+ '?updated_at=' + orderResources['20x30_mosaic_preview'].updated_at;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target="_blank";
        document.body.appendChild(link);
        link.click();
        console.log("clicker");
    }

    closeMozaixLightBox = () => {
        this.setState({
            isOpen: false
        })
    }

    showImageLoader = (state) => {
        this.setState({
            showLoader: state
        })
    }

    loader = () => {
        return (
            <LoadingOverlay
                    active={this.state.showLoader}
                    disabled={Number(this.props.orderUploadInfo.photos_submitted_for_video_production.value) === 1 || isMobile || User.hasRole([Roles.TRAVELER])}
                    className="overlayModify"
                    log={console.log("loading overlay log...",this.state.showLoader)}
                    spinner={<PulseLoader />}
                    text='Please wait... Images are processing and do not release the mouse button '
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            size: '600px',
                            '& svg circle': {
                                stroke: 'rgba(192,192,192,0.8)'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(0,0,0,0.85)'
                        }),
                    }}
                ></LoadingOverlay>
        )
    }

    saveVideoCount = (track_type, video_type) => {
        console.log("track_type", track_type);
        console.log("video_type", video_type);
        console.log("user_id", User.user.id);
        const data ={
            order_id: this.props.orderUploadInfo.id,
            track_type: track_type,
            device_type: 'web',
            video_type: video_type,
            user_id: User.user.id
            // user_id: User.order('id')
        };
        reportService.saveVideoCount(data);
    }

    handleGalleryMode = (key) => {
        this.setState({
            photoGallerySection: key === 'photoGallerySection',
            videoGallerySection: key === 'videoGallerySection',
        });
    }
    render() {
        const { isOpen, is_ucd_after_return_date } = this.state;
        const {orderUploadInfo, orderResources, allow_reorder_days_diff} = this.props;
        const {photos_submitted_for_video_production} = orderUploadInfo;
        const { is_5_years_old_order } = allow_reorder_days_diff;
        let showUploader = false;
        let showGallery = false;
        let errorMessage;
        let photoGalleryError;
        console.log("orderResources", orderResources);
        //Gallery Conditions
        const galleryErrors = photoGalleryErrors(orderUploadInfo, Status, photoGalleryError, User, React, Roles, showGallery, Link, this.props.history);
        showGallery = galleryErrors.showGallery;
        photoGalleryError = galleryErrors.photoGalleryError

        //Uploader Conditions
        const uploadErrors = uploaderErrors(orderUploadInfo, errorMessage, showUploader, photos_submitted_for_video_production, this.uploadDateHandler, Status, Roles, Link, User, React);
        showUploader = uploadErrors.showUploader;
        errorMessage = uploadErrors.errorMessage;

        const charLimit = 150;
        const drag_drop_text = "If video photo sequence is important, please rearrange photos by dragging & dropping. Otherwise, photo sequence may be randomized. To drag & drop, left click a photo to select it, then if you would like to select more photos to rearrange, simply hold the CRTL (CMD on Mac) key and continue left clicking. Once you have selected the photos you wish to rearrange, drag & drop them into the desired position."
        const sort_by_date_teken_text = "This gallery is in date taken order. If the photos are not in the correct order, it's possible the date/time information was lost when copied. Photos that do not have date taken information will be placed at the bottom. To get the photos that do not have date taken information in the correct sequence, they will need to be dragged & dropped into the correct position and SAVED. WARNING: If any new sorting, you will not be able to recover previous changes."
        const restore_to_original_order_text = "To sort photos by original order, click the Restore toOriginal Order button. This will restore the photos into the original order that they were uploaded. WARNING: Once photos are restored to original order, drag &amp; drop sorting can not be recovered."
        const rensomize_photo_text = "To randomize the sequence of your photos, click the Randomize Photos button. WARNING: Once photos are randomized, drag and drop sorting can not be recovered."
        
        const keep_sake_or_mozaix = "Before your order is submitted into production you may select what photos are used in your product purchased (the video or mozaix), by marking them selected/unselected. If you do not select/unselect photos, our editors will select some of the best to use in the video/mozaix. You will have access to download all the photos in the gallery. WARNING: If any new sorting, you will not be able to recover previous changes"
        const photo_gallery = "This gallery is in date taken order. If the photos are not in the correct order, it's possible the date/time information was lost when copied/uploaded. Photos that do not have date taken information are placed at the bottom of the photo gallery."
        const uploaded_photos = "You and your guests are able to upload small video clips. If a keepsake video is ordered you must mark what videos will be included on the keepsake with the photos. These videos are available to download. You may delete any video you would not like on your keepsake."

        return (
            <React.Fragment>

                {/* {
                    (showLoader) &&
                    this.loader()

                } */}



                {
                    (Number(orderUploadInfo.finalize_by_qa.value) === 1 && !('allow_online_reorder' in orderUploadInfo) && !('cleared_date' in orderUploadInfo)) &&
                    <div className='container mt-xlg'>
                        <Row>
                            <Col md={12}>
                            <h1 className='text_22 mb-md'><strong>{orderUploadInfo.group_name}</strong></h1>
                                <h4 className='text_18 mb-md'>{orderUploadInfo.destinations}</h4>
                                <h1 className='text_18 mb-md'><strong>Your Full Video</strong></h1>
                            </Col>
                        </Row>
                        <div className='fullVideo'>
                        <div className='mb-15'>
                        {
                                orderResources.full_video &&
                                <React.Fragment>
                                    <video onPlay={(e) => {e.preventDefault(); this.saveVideoCount('view', 'full')}} style={{maxWidth: '100%'}} controls>
                                        <source src={orderResources.full_video.file_path} type='video/mp4'/>
                                    </video>
                                    <div className='flexElem'>

                                        <div className="fb-share-button mt-md " data-layout="button"
                                                data-size="large">
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                window.open(`https://www.facebook.com/sharer/sharer.php?u=${orderResources.full_video.file_path}`, 'popup', 'height=600,width=600')
                                            }} style={{
                                                color: '#fff',
                                                background: '#3b5998',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                                href={`https://www.facebook.com/sharer/sharer.php?u=${orderResources.full_video.file_path}`}><i
                                                className='fa fa-facebook'></i> Share</a>
                                        </div>
                                        {/* <div className="ml-md mt-md">
                                            <strong>Number of Streams: </strong>
                                            <span>{orderUploadInfo.full_video_view_count}</span>
                                        </div>
                                        <div className="ml-md mt-md">
                                            <strong>Number of Downloads: </strong>
                                            <span>{orderUploadInfo.full_video_download_count}</span>
                                        </div> */}
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        <div style={{marginBottom: 22}}>
                        <div className="uploader_actions" style={{justifyContent: 'start', marginBottom:26}}>
                                {
                                    orderResources.highlight_video &&
                                    <button onClick={() => {
                                        this.toggleModal('showHighlightVideo')
                                    }} className='themeBtn_12 blue_theme_button'><i className='fa fa-play'></i> Highlight
                                        Video
                                    </button>
                                }
                                {
                                    orderResources.full_video &&
                                    <DownloadFile extraClass='themeBtn_12 blue_theme_button' multiple={false}
                                                    title={'Download Full Video'} whiteDownloadIcon={true} files={{
                                        'file_title': orderResources.full_video.file_title,
                                        'file_path': orderResources.full_video.file_path
                                        }}
                                        saveVideoCount={this.saveVideoCount}
                                        video_type={'full'}
                                    />
                                }
                                {
                                    is_5_years_old_order && orderUploadInfo && orderUploadInfo.allow_reorder
                                    ?
                                    <Link to='/keep_sake' className='themeBtn_12 blue_theme_button'>Additional Keepsakes</Link>
                                    :
                                        !is_5_years_old_order
                                        ?
                                        <Link to='/keep_sake' className='themeBtn_12 blue_theme_button'>Additional Keepsakes</Link>
                                        :
                                        <button onClick={() => {this.toggleModal('reorderKeepsakeModal')}} className='themeBtn_12 blue_theme_button'>Additional Keepsakes</button>
                                    }
                            </div>

                            <ThemeModal modalClass="section-Frontend frontEndModal"
                                        title="Additional Keepsakes"
                                        show={this.state.reorderKeepsakeModal} hide={() => {this.setState({reorderKeepsakeModal: false})}}>
                            {
                                <Transition timeout={600}>
                                    <p className="text_16">We may still have your memories.
                                    <br /><br />
                                    Call <Link className="linkColor" to="Contact_Us">888-533-7637 x213</Link> to see if available.
                                    </p>
                                </Transition>
                            }
                            </ThemeModal>
                            {
                                orderResources['20x30_mosaic_preview'] ?

                                    <div className='mt-md'>
                                        <Row>
                                            <Col md={8} style={{padding: '0'}}>
                                                <img style={{width: '93%', border: '1px solid #eedddd', marginLeft: '14px', cursor: 'pointer'}} onClick={this.handleShowDialog} className='mr-md' src={orderResources['20x30_mosaic_preview'].file_path} alt="mosaic"/>
                                                {/* <img style={{width: '100%', border: '1px solid #eedddd'}} onClick={this.handleShowDialog} className='mr-md' src={orderResources['20x30_mosaic_preview'].file_path} alt="mosaic"/> */}
                                            </Col>
                                            <Col md={4} style={{padding: '0'}} className="gtvIcons" >
                                                <img className='' src={s3StaticPath('img/wtk_usb_rotate.png')} alt=""/>
                                                {/* <img className='mr-md' src={s3StaticPath('img/product_sample_DVD.png')} alt=""/>
                                                <img className='' src={s3StaticPath('img/product_sample_picture_disc.png')} alt=""/> */}
                                            </Col>
                                        </Row>
                                    </div>
                                :
                                <div className='mt-md' style={{display: 'flex', alignItems: 'start'}}>
                                        <img className='mr-md' src={s3StaticPath('img/wtk_usb_rotate.jpg')} alt=""/>
                                        {/* <img className='mr-md' src={s3StaticPath('img/product_sample_DVD.png')} alt=""/>
                                        <img src={s3StaticPath('img/product_sample_picture_disc.png')} alt=""/> */}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                }
                {
                    showUploader ?
                        <React.Fragment>
                            {
                                !User.hasRole([Roles.TRAVELER]) &&
                                <section className="section" id="uploaderTabs">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tabsContainer">
                                                    <div className="tabsHeader flexElem spaceBetween alignCenter" style={{border: 'none', borderBottom: '1px solid #d3d6dc'}}>
                                                        <div className="flexElem respFlex alignCenter">
                                                            <div className="mr-xlg mb-sm">
                                                                <a onClick={() => {
                                                                            this.changeTab(1)
                                                                        }}
                                                                        className={"pGtabsHeadings anchorHover " + (this.state.activeTabNo === 1 ? "pGtabActive" : "")}>Photo
                                                                    Admin Editing Tools
                                                                </a>
                                                            </div>
                                                            <div className="ml-xlg mr-lg mb-sm">
                                                                <a onClick={() => {
                                                                            this.changeTab(2)
                                                                        }}
                                                                        className={"pGtabsHeadings anchorHover " + (this.state.activeTabNo === 2 ? "pGtabActive" : "")}>Photo Sorting/Uploaded Video Options
                                                                </a>
                                                            </div>
                                                            <div className="ml-xlg mr-lg mb-sm">
                                                                <a onClick={() => {
                                                                            this.changeTab(3)
                                                                        }}
                                                                        className={"pGtabsHeadings anchorHover " + (this.state.activeTabNo === 3 ? "pGtabActive" : "")}>What
                                                                    We Do
                                                                </a>
                                                            </div>
                                                            <div className="ml-xlg mr-lg mb-sm">
                                                                <a target="_blank" href="/customize_titles" className="pGtabsHeadings anchorHover " >Customize your Titles and Music
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <span onClick={(e) => this.toggleTabs(e)}
                                                                  className="tabsCollapser secondary hover_dark">
                                                                <FiChevronDown/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.activeTabNo === 1 &&
                                                        <Row className="mb25 tabsContentUpdated">
                                                            <Col xs={12} sm={4} className="mt10">
                                                                <div className="border1 whiteBG"  style={{minHeight: '520px'}}>
                                                                    <img
                                                                        src={s3StaticPath('img/gallery/wtk/photo_gallery.png')}
                                                                        alt="App Features 1"
                                                                        className="mw100Force"
                                                                    />
                                                                    <div className="roundedOverlay">
                                                                        <img src={s3StaticPath('img/gallery/wtk/photo_gallery_icon.png')} alt="photo_gallery_icon" />
                                                                    </div>
                                                                    <div className="px1-25 py25 text-center" >
                                                                        <h6 className="secondary font-weight-bold lineH">
                                                                            Photo Gallery
                                                                        </h6>
                                                                        <span className="secondary mt25">
                                                                            <ReactReadMoreReadLess
                                                                                charLimit={charLimit}
                                                                                readMoreText={"Read more ▼"}
                                                                                readLessText={"Read less ▲"}
                                                                            >
                                                                                {photo_gallery}
                                                                            </ReactReadMoreReadLess>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} sm={4} className="mt10">
                                                                <div className="border1 whiteBG"  style={{minHeight: '520px'}}>
                                                                    <img
                                                                        src={s3StaticPath('img/gallery/wtk/delete_photos.png')}
                                                                        alt="App Features 1"
                                                                        className="mw100Force"
                                                                    />
                                                                    <div className="roundedOverlay">
                                                                        <img src={s3StaticPath('img/gallery/delete_icon.png')} alt="Group Photo Sharing" height={60} />
                                                                    </div>
                                                                    <div className="px1-25 py25 text-center">
                                                                        <h6 className="secondary font-weight-bold lineH">
                                                                            Delete Photos
                                                                        </h6>
                                                                        <span className="secondary mt25">
                                                                            If you do NOT want a photo included, check the delete
                                                                            box under that photo. WARNING: Once deleted, it cannot
                                                                            be recovered.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            {/* <Col xs={12} sm={3} className="mt10">
                                                                <div className="border1 whiteBG"  style={{minHeight: '520px'}}>
                                                                    <img
                                                                        src={s3StaticPath('img/gallery/wtk/keep_sake_photomoziax.png')}
                                                                        alt="App Features 1"
                                                                        className="mw100Force"
                                                                    />
                                                                    <div className="roundedOverlay">
                                                                        <img src={s3StaticPath('img/gallery/wtk/keep_sake_photomoziax_icon.png')} alt="Lasting Memories" height={60} />
                                                                    </div>
                                                                    <div className="px1-25 py25 text-center">
                                                                        <h6 className="secondary font-weight-bold lineH">
                                                                            Select Photos for Keepsake Video or PhotoMozaix
                                                                        </h6>
                                                                        <span className="secondary mt25">
                                                                            <ReactReadMoreReadLess
                                                                                charLimit={135}
                                                                                readMoreText={"Read more ▼"}
                                                                                readLessText={"Read less ▲"}
                                                                            >
                                                                                {keep_sake_or_mozaix}
                                                                            </ReactReadMoreReadLess>
                                                                        </span>
                                                                    </div>
                                                                    </div>
                                                            </Col> */}
                                                            <Col xs={12} sm={4} className="mt10">
                                                                <div className="border1 whiteBG"  style={{minHeight: '520px'}}>
                                                                    <img
                                                                        src={s3StaticPath('img/gallery/wtk/save_photo_changes.png')}
                                                                        alt="App Features 1"
                                                                        className="mw100Force"
                                                                    />
                                                                    <div className="roundedOverlay">
                                                                        <img src={s3StaticPath('img/gallery/save_icon.png')} alt="Lasting Memories" height={60} />
                                                                    </div>
                                                                    <div className="px1-25 py25 text-center">
                                                                        <h6 className="secondary font-weight-bold lineH">
                                                                            Save Photo Changes
                                                                        </h6>
                                                                        <span className="secondary mt25">
                                                                            If you make any photos changes on this page, click the
                                                                            Save Photo Changes button before leaving this page.
                                                                        </span>
                                                                    </div>
                                                                    </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {
                                                        this.state.activeTabNo === 2 &&
                                                        <React.Fragment>
                                                            <Row className="mb25 tabsContentUpdated">
                                                                <Col xs={12} sm={3} className="mt10">
                                                                    <div className="border1 whiteBG"  style={{minHeight: '453px'}}>
                                                                        <img
                                                                            src={s3StaticPath('img/gallery/drag_and_drop.jpg')}
                                                                            alt="App Features 1"
                                                                            className="mw100Force"
                                                                        />
                                                                        <div className="roundedOverlay">
                                                                            <img src={s3StaticPath('img/gallery/drag_and_drop_icon.png')} alt="Documents, Messaging, Map" />
                                                                        </div>
                                                                        <div className="px1-25 py25 text-center" >
                                                                            <h6 className="secondary font-weight-bold lineH">
                                                                                Drag & Drop Photos
                                                                            </h6>
                                                                            <span className="secondary mt25">
                                                                                <ReactReadMoreReadLess
                                                                                    charLimit={charLimit}
                                                                                    readMoreText={"Read more ▼"}
                                                                                    readLessText={"Read less ▲"}
                                                                                >
                                                                                    {drag_drop_text}
                                                                                </ReactReadMoreReadLess>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={3} className="mt10">
                                                                    <div className="border1 whiteBG"  style={{minHeight: '453px'}}>
                                                                        <img
                                                                            src={s3StaticPath('img/gallery/sort_by_date_taken.jpg')}
                                                                            alt="App Features 1"
                                                                            className="mw100Force"
                                                                        />
                                                                        <div className="roundedOverlay">
                                                                            <img src={s3StaticPath('img/gallery/date_take_icon.png')} alt="Group Photo Sharing" height={60} />
                                                                        </div>
                                                                        <div className="px1-25 py25 text-center">
                                                                            <h6 className="secondary font-weight-bold lineH">
                                                                                Sort By Date Taken
                                                                            </h6>
                                                                            <span className="secondary mt25">
                                                                                <ReactReadMoreReadLess
                                                                                    charLimit={charLimit}
                                                                                    readMoreText={"Read more ▼"}
                                                                                    readLessText={"Read less ▲"}
                                                                                >
                                                                                    {sort_by_date_teken_text}
                                                                                </ReactReadMoreReadLess>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={3} className="mt10">
                                                                    <div className="border1 whiteBG"  style={{minHeight: '453px'}}>
                                                                        <img
                                                                            src={s3StaticPath('img/gallery/randomize_photos.jpg')}
                                                                            alt="App Features 1"
                                                                            className="mw100Force"
                                                                        />
                                                                        <div className="roundedOverlay">
                                                                            <img src={s3StaticPath('img/gallery/randomize_photos_icon.png')} alt="Documents, Messaging, Map" />
                                                                        </div>
                                                                        <div className="px1-25 py25 text-center" >
                                                                            <h6 className="secondary font-weight-bold lineH">
                                                                                Randomize Photos
                                                                            </h6>
                                                                            <span className="secondary mt25">
                                                                                <ReactReadMoreReadLess
                                                                                    charLimit={charLimit}
                                                                                    readMoreText={"Read more ▼"}
                                                                                    readLessText={"Read less ▲"}
                                                                                >
                                                                                    {rensomize_photo_text}
                                                                                </ReactReadMoreReadLess>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={3} className="mt10">
                                                                    <div className="border1 whiteBG"  style={{minHeight: '453px'}}>
                                                                        <img
                                                                            src={s3StaticPath('img/gallery/wtk/upload_video.png')}
                                                                            alt="App Features 1"
                                                                            className="mw100Force"
                                                                        />
                                                                        <div className="roundedOverlay">
                                                                            <img src={s3StaticPath('img/gallery/wtk/upload_video_icon.png')} alt="Documents, Messaging, Map" />
                                                                        </div>
                                                                        <div className="px1-25 py25 text-center" >
                                                                            <h6 className="secondary font-weight-bold lineH">
                                                                                Uploaded Videos
                                                                            </h6>
                                                                            <span className="secondary mt25">
                                                                                <ReactReadMoreReadLess
                                                                                    charLimit={charLimit}
                                                                                    readMoreText={"Read more ▼"}
                                                                                    readLessText={"Read less ▲"}
                                                                                >
                                                                                    {uploaded_photos}
                                                                                </ReactReadMoreReadLess>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>

                                                    }
                                                    {
                                                        this.state.activeTabNo === 3 &&
                                                        <Row className="mb25 tabsContentUpdated">
                                                            <Col xs={12} sm={6} className="mt10">
                                                                <div className="border1 whiteBG pgTab3"  style={{minHeight: '426px'}}>
                                                                    <img
                                                                        src={s3StaticPath('img/gallery/wtk/photo_editing.png')}
                                                                        alt="App Features 1"
                                                                        className="tab3mw100Force"
                                                                    />
                                                                    <div className="roundedOverlay">
                                                                        <img src={s3StaticPath('img/gallery/photo_editing_icon.png')} alt="Documents, Messaging, Map" />
                                                                    </div>
                                                                    <div className="px1-25 py25 text-center" >
                                                                        <h6 className="secondary font-weight-bold lineH">
                                                                            Photo Editing
                                                                        </h6>
                                                                        <span className="secondary mt25">
                                                                            Rotating, Color/Contrast Adjustment, Red Eye Adjustment
                                                                            If not selected, we will select the photos used for the Keepsake video or PhotoMozaix.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} sm={6} className="mt10">
                                                                <div className="border1 whiteBG pgTab3"  style={{minHeight: '426px'}}>
                                                                    <img
                                                                        src={s3StaticPath('img/gallery/create_your_group_travel_videos.jpg')}
                                                                        alt="App Features 1"
                                                                        className="tab3mw100Force"
                                                                    />
                                                                    <div className="roundedOverlay">
                                                                        <img src={s3StaticPath('img/gallery/create_group_icon.png')} alt="Group Photo Sharing" height={60} />
                                                                    </div>
                                                                    <div className="px1-25 py25 text-center">
                                                                        <h6 className="secondary font-weight-bold lineH">
                                                                            Create Your Keepsake
                                                                        </h6>
                                                                        <span className="secondary mt25">
                                                                            We create your keepsake ordered, such as a storytelling video or 
                                                                            20x30 PhotoMozaix print using some of the photos taken of your 
                                                                            special event for you to enjoy for a lifetime! All photos will be available to download.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                            <section id="photo_gallery" className="section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="flexElem respFlex spacing alignCenter spaceBetween">
                                                <div>
                                                    <h1 className="panel_heading small"><span
                                                        style={{'fontSize': 'xxx-large'}}><strong>{orderUploadInfo.group_name}</strong></span>
                                                    </h1>
                                                </div>
                                            <div className='gallery_video_icons'>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id='Videos'>Photo Gallery </Tooltip>
                                                    }>
                                                    <button onClick={() => {this.handleGalleryMode('photoGallerySection')}} className={`themeBtn_12 block hover_dark ${this.state.videoGallerySection ? ' ': ' blue_theme_button'}`}>
                                                        <img style={{cursor: 'pointer'}} src={s3StaticPath('img/gallery/wtk/photo_gallery_icon.png')} alt="photo_gallery_icon" width={24}/>
                                                    </button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id='Videos'>Video Gallery</Tooltip>
                                                    }>

                                                    <button onClick={() => {this.handleGalleryMode('videoGallerySection')}} className={`themeBtn_12 block hover_dark ${this.state.photoGallerySection ? '': ' blue_theme_button'}`}>
                                                        <img  style={{cursor: 'pointer'}} src={s3StaticPath('img/gallery/wtk/upload_video_icon.png')} alt="Documents, Messaging, Map" width={24}/>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.photoGallerySection &&
                                    <Transition in={this.state.showUploader} timeout={600}>
                                        {
                                            state => (
                                                <div className="uploader_box_contains">
                                                    <div id='top' style={{transition: `all 0.6s`}}
                                                        className="container uploader_box">
                                                        {/* <span onClick={(e) => {
                                                            this.toggleUploader(e, false)
                                                        }}
                                                            className="close_uploader blue_gradient hover_dark"><MdClose/></span> */}
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h3 className="panel_heading small text_left_center_991">Your
                                                                    Photo Uploader</h3>

                                                                <div className="uploader_box_container flexElem respFlex">
                                                                    <div className="uploader_box_col big">
                                                                        <div className="addName infoBox redBox">
                                                                            <div><strong className="red_bold">TAKE THE
                                                                                CREDIT!</strong> Before uploading, please
                                                                                enter the first and last name of the person
                                                                                who took the photos that you are about to
                                                                                upload then click the "Add Name". The
                                                                                photographer`s name will appear under photos
                                                                                on the app &amp; website.
                                                                            </div>

                                                                            <div className="flexElem respFlex addNameForm">
                                                                                <div><input name='firstName'
                                                                                            value={this.state.photoUploader.firstName}
                                                                                            onChange={this.inputChange}
                                                                                            type="text"
                                                                                            className="themeInput roundedInput hasIcon nameIcon block"
                                                                                            placeholder="First Name"/></div>
                                                                                <div><input name='lastName'
                                                                                            value={this.state.photoUploader.lastName}
                                                                                            onChange={this.inputChange}
                                                                                            type="text"
                                                                                            className="themeInput roundedInput hasIcon nameIcon block"
                                                                                            placeholder="Last Name"/></div>
                                                                                <div>
                                                                                    <button
                                                                                        onClick={this.registerPhotoUploader}
                                                                                        className="themeBtn_12 blue_theme_button block hover_dark">Add
                                                                                        Name
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.photoUploader.firstName &&
                                                                                <div className='mt-md'>Your Photographer
                                                                                    is <strong>{this.state.photoUploader.firstName + " " + this.state.photoUploader.lastName}</strong>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {/* <Link to='/photo_gallery' className="themeBtn_14 hover_dark purple_gradient">View Uploaded Photos</Link> */}

                                                                        <div className="flexElem respFlex uploadActions">
                                                                            <div>
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    overlay={
                                                                                        <Popover id="uploadFileInfo">
                                                                                            <table className="mb-xs">
                                                                                                <tbody>
                                                                                                <tr>
                                                                                                    <td className="p-sm pl-xs">
                                                                                                        <span>Acceptable File Types:</span>
                                                                                                    </td>
                                                                                                    <td className="pl-sm">
                                                                                                        <span
                                                                                                            className="linkColor">.heic, .jpg, .png, .jpeg, .webp, &amp; .gif</span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr style={{borderTop: "1px solid #ccc"}}>
                                                                                                    <td className="p-sm pl-xs">
                                                                                                        <span>Max File Size:</span>
                                                                                                    </td>
                                                                                                    <td className="pl-sm">
                                                                                                        <span
                                                                                                            className="linkColor">20 MBs </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </Popover>
                                                                                    }>
                                                                                    <button onClick={() => {
                                                                                        this.uploadForm.open()
                                                                                    }}
                                                                                            className="selectPhoto currAction blue_theme_button hover_dark alignCenter">
                                                                                        <img
                                                                                            src={s3StaticPath('img/operator/photoUpload.png')}
                                                                                            alt="Select Photos To Upload"/>
                                                                                        <span>Select Photos To Upload</span>
                                                                                        <div style={{display: 'none'}}>
                                                                                            <FileUpload
                                                                                                ref={el => {
                                                                                                    this.uploadForm = el
                                                                                                }}
                                                                                                setResourceFile={this.setResourceFile}
                                                                                                multiple={true}
                                                                                                allowedFormatsStringMimePro={'image/jpeg,image/png,image/gif,image/heic, image/hevc, image/heif, image/webp, image/DNG, image/tiff, image/bmp, image/wmf'}//image/heif, image/heic, image/heif-sequence, image/heic-sequence
                                                                                                allowedFormatsStringExtPro={'jpeg,png,heic,webp,dng,tiff,tif,bmp,wmf,gif,heif'}
                                                                                            />
                                                                                        </div>
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                            <div>
                                                                                <button onClick={this.cancelUpload}
                                                                                        className="cancelUpload currAction blue_theme_button hover_dark alignCenter">
                                                                                    <img
                                                                                        src={s3StaticPath('img/operator/cancelUpload.png')}
                                                                                        alt="Cancel Upload"/>
                                                                                    <span>Cancel Upload</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="uploadDetails flexElem respFlex alignCenter spaceBetween mt-md mb-md">
                                                                            <div
                                                                                className="text_16">Currently {this.state.uploadedFiles} of {this.state.filesToUpload} uploaded
                                                                            </div>

                                                                            <div className="text_16 p-xs troubleUploading">
                                                                                Trouble Uploading Photos

                                                                                <OverlayTrigger
                                                                                    trigger="click"
                                                                                    placement="bottom"
                                                                                    overlay={
                                                                                        <Popover id="troubleUploading">
                                                                                            <ul style={{
                                                                                                listStyle: "disc",
                                                                                                paddingLeft: "20px"
                                                                                            }}>
                                                                                                <li className="pl-xs text_14 lh_2">Try
                                                                                                    updating your internet
                                                                                                    browser.
                                                                                                </li>
                                                                                                <li className="pl-xs mt-sm text_14 lh_2">Try
                                                                                                    using a different
                                                                                                    internet browser. We
                                                                                                    recommend <a
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        href="https://www.mozilla.org/en-US/firefox/new/"
                                                                                                        className="linkColor">Firefox</a> or <a
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        href="https://www.google.com/chrome/"
                                                                                                        className="linkColor">Chrome.</a>
                                                                                                </li>
                                                                                                <li className="pl-xs mt-sm text_14 lh_2">If
                                                                                                    the above does not work,
                                                                                                    please <Link
                                                                                                        target='_blank'
                                                                                                        className="linkColor"
                                                                                                        to="Contact_us">Contact
                                                                                                        Us</Link></li>
                                                                                            </ul>
                                                                                        </Popover>
                                                                                    }>
                                                                                    <span
                                                                                        className="ml-xs questionTooltip">?</span>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <ImagePreview
                                                                                filesToPreview={this.state.files}
                                                                                removeFile={this.removeFile}/>
                                                                            {this.props.progress !== 0 &&
                                                                            <span>{this.props.progress}</span>}
                                                                        </div>
                                                                        {
                                                                            !this.state.filesToUpload ?
                                                                                ""
                                                                                :
                                                                                <div className="sec_line">&nbsp;</div>
                                                                        }
                                                                        <div className='mt-md uploaderProgBars'>
                                                                            <table className='table'>
                                                                                <tbody>
                                                                                {
                                                                                    this.state.files.map((file, index) => {
                                                                                        return (
                                                                                            <tr key={file.name.replace(/[- .()]/g, '') + index}>
                                                                                                <td className="uploaderFileName"
                                                                                                    style={{
                                                                                                        width: "30%",
                                                                                                        wordBreak: 'break-word'
                                                                                                    }}>{file.name}</td>
                                                                                                <td className="uploaderBar"
                                                                                                    style={{width: "55%"}}>
                                                                                                    <span
                                                                                                        id={file.name.replace(/[- .()]/g, '') + '_speed'}></span>
                                                                                                    <span
                                                                                                        id={file.name.replace(/[- .()]/g, '') + '_time'}></span>
                                                                                                    <span
                                                                                                        id={file.name.replace(/[- .()]/g, '') + '_loaded'}></span>
                                                                                                    <span
                                                                                                        id={file.name.replace(/[- .()]/g, '') + '_total'}></span>
                                                                                                    <div
                                                                                                        className="progress">
                                                                                                        <div
                                                                                                            className="progress-bar progress-bar-striped progress-bar-success active"
                                                                                                            role="progressbar"
                                                                                                            aria-valuenow="100"
                                                                                                            id={file.name.replace(/[- .()]/g, '')}
                                                                                                            aria-valuemin="0"
                                                                                                            aria-valuemax="100"
                                                                                                            style={{width: "0%"}}>
                                                                                                            0%
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {
                                                                                                            'message' in file &&
                                                                                                            <ul style={{'backgroundColor': '#f2dede'}} log={console.log("file.message",file.message)}>
                                                                                                                {
                                                                                                                    file.message.map((m, i) => {
                                                                                                                        return (
                                                                                                                            <li key={i + 'k'}>{m}</li>)
                                                                                                                    })
                                                                                                                }
                                                                                                            </ul>
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="uploaderFileAction"
                                                                                                    style={{width: "15%"}}>
                                                                                                    <i onClick={() => {
                                                                                                        this.cancelAxiosUpload(file)
                                                                                                    }}
                                                                                                    className='fa fa-times'></i>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                    <OrderUploadInfo orderUploadInfo={orderUploadInfo}/>
                                                                    <button onClick={this.uploadSubmitHandler}
                                                                            className="themeBtn_12 doneBtn blue_theme_button hover_dark">Upload
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Transition>
                                }
                                {
                                    this.state.videoGallerySection &&
                                    <VideoGallery orderUploadInfo={orderUploadInfo}/>
                                }
                            </section>
                            <section className="section" id="uploaderTabs">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="tabsContainer">
                                                <div className="tabsHeader flexElem spaceBetween alignCenter" style={{border: 'none', borderBottom: '1px solid #d3d6dc'}}>
                                                    <div className="flexElem respFlex alignCenter">
                                                        <div className="mr-xlg mb-sm">
                                                            <a onClick={() => {
                                                                        this.changeGalleryTab(1)
                                                                    }}
                                                                    className={"pGtabsHeadings anchorHover " + (this.state.galleryTabsNo === 1 ? "pGtabActive" : "")}>
                                                                Your Uploaded Documents
                                                            </a>
                                                        </div>
                                                        {
                                                            Number(photos_submitted_for_video_production.value) !== 1 &&
                                                            <div className="ml-xlg mr-lg mb-sm">
                                                                <a onClick={() => {
                                                                            this.changeGalleryTab(2)
                                                                        }}
                                                                        className={"pGtabsHeadings anchorHover " + (this.state.galleryTabsNo === 2 ? "pGtabActive" : "")}>
                                                                    Notes to the Production Team Comments
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div>
                                                        <span onClick={(e) => this.toggleDocumentTabs(e)}
                                                                className="tabsCollapserDocuments secondary hover_dark">
                                                            <FiChevronDown/>
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.galleryTabsNo === 1 &&
                                                    User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER]) &&
                                                        <div className="uploader_box_doc_contains">
                                                            <div id='top' style={{transition: `all 0.6s`}}
                                                                    className="container uploader_box">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Documents notEditable
                                                                                    formSubmitted={this.state.submitOrderDocuments}
                                                                                    id={User.getProperty('order.id')}
                                                                                    formType={'edit'} isSchedule={false}
                                                                                    header={'Documents'}
                                                                                    agency_id={User.agency('id')}
                                                                                    resourceTypeText={'order_documents'}/>
                                                                    </div>
                                                                    {
                                                                        (Number(orderUploadInfo.approved_for_video_upload.value) === 1) &&
                                                                        <div className="col-md-6">
                                                                            <Documents notEditable
                                                                                    formSubmitted={this.state.submitOrderDocuments}
                                                                                    id={User.getProperty('order.id')}
                                                                                    formType={'edit'} isSchedule={false}
                                                                                    header={'Video Upload/View'}
                                                                                    resourceTypeText={'audio_video'}/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                {
                                                    this.state.galleryTabsNo === 2 &&
                                                    <div className='uploader_box_doc_contains'>
                                                        <OrderComments showSteps={false} hideTitle={true}
                                                            showOnly={['Production']}
                                                            addExceptTab={['TO_GL_Production']} 
                                                            modelId={User.order('id')}
                                                            order_details={orderUploadInfo}/>
                                                    </div>

                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                           
                        </React.Fragment>
                        :
                        errorMessage &&
                        <div className='container'>
                            <div style={{marginTop: '10px'}} className='alert alert-danger' role='alert'>
                                {errorMessage}
                            </div>
                        </div>
                }


                {
                    this.state.videoGallerySection ?
                    <Videos orderId={orderUploadInfo.id}/>
                    :
                    showGallery ?
                        <React.Fragment>
                            <section id="companyImagesInfo">
                               <PhotoGallery
                                    showImageLoader={this.showImageLoader}
                                    toggleUploader={this.state.toggleUploader}
                                    uploadMore={(e) => {
                                        this.toggleUploader(e, true)
                                    }}
                                    extraHtml={
                                        {
                                            submitBtn: User.hasRole(Roles.PHOTO_ADMIN) && orderUploadInfo.total_approved_photos > 0 && new Date() >= new Date(orderUploadInfo.return_date) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                                            // new Date() <= new Date(orderUploadInfo.upload_cut_off_date) &&
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id='Videos'>
                                                            READY TO GET YOUR <strong
                                                            className="semibold">VIDEOS?</strong>
                                                        </Tooltip>
                                                    }>
                                                    <button onClick={() => {
                                                        this.toggleModal('submitVideoProductionModal')
                                                    }}
                                                            className='themeBtn_14 doneBtn block blue_theme_button hover_dark'>Submit
                                                        for video production
                                                    </button>
                                                </OverlayTrigger>,
                                            galleryHeading: Number(photos_submitted_for_video_production.value) === 1 ? 'Photo Gallery' : ''
                                        }
                                    }
                                />
                            </section>

                            <ThemeModal modalClass="section-Frontend frontEndModal"
                                        title={this.state.production_submitted ? 'Thank You For Submitting Photos For Video Production' : 'Submit For Video Production'}
                                        show={this.state.submitVideoProductionModal} hide={() => {
                                this.toggleModal('submitVideoProductionModal')
                            }}>
                                {
                                    !this.state.production_submitted ?
                                        <Transition timeout={600} in={!this.state.production_submitted}>
                                            <p className="text_16">Have you finished submitting all photos for your
                                                video and making any desired photo editing changes? If not,
                                                click <strong>Cancel</strong>. If so, click <strong>Submit</strong> and
                                                photo uploading will close for this order and your photos will be
                                                submitted for video production.</p>
                                        </Transition>
                                        :
                                        <Transition timeout={600} in={this.state.production_submitted}>
                                            <p className="text_16">Thank You For Submitting Photos For Video Production!
                                                Our video producers will now be notified that you have submitted photos
                                                and will soon begin photo editing and video production.
                                                Photos are usually edited within 1-2 weeks of receiving your photos.
                                                Once your photos have been edited (color correction, red-eye adjustment,
                                                rotating, etc.), you will be able to log in to the app or this website
                                                to view the edited photos.
                                                Normal production time for you keepsake video is 2-3 weeks and will be
                                                viewable through the app or on any device using your group's private
                                                login.
                                                If you have any questions, please <Link className="linkColor"
                                                                                        to="Contact_Us">Contact
                                                    Us</Link>. </p>
                                        </Transition>
                                }
                                {
                                    !this.state.production_submitted &&
                                    <div className='mt-md text-center'>
                                        <button onClick={this.submitForVideoProduction}
                                                className='themeBtn_14 doneBtn blue_theme_button hover_dark ml-sm mr-sm pt-sm pb-sm'>Submit
                                        </button>
                                        <button onClick={() => {
                                            this.toggleModal('submitVideoProductionModal')
                                        }}
                                                className='themeBtn_14 doneBtn purple_gradient hover_dark ml-sm mr-sm pt-sm pb-sm'>Cancel
                                        </button>
                                    </div>
                                }
                            </ThemeModal>
                            <ThemeModal size='lg' modalClass="section-Frontend frontEndModal" title='Highlight Video'
                                        show={this.state.showHighlightVideo} hide={() => {
                                this.toggleModal('showHighlightVideo')
                            }}>
                                {
                                    orderResources.highlight_video &&
                                    <React.Fragment>
                                        <video onPlay={(e) => {e.preventDefault(); this.saveVideoCount('view', 'highlight')}} style={{maxWidth: '100%'}} controls>
                                            <source src={orderResources.highlight_video.file_path} type='video/mp4'/>
                                        </video>
                                        <div className='text-center p-md'>
                                            <DownloadFile extraClass='themeBtn_12 limegreen_gradient' multiple={false}
                                                          title={'Download Highlight Video'} files={{
                                                    'file_title': orderResources.highlight_video.file_title,
                                                    'file_path': orderResources.highlight_video.file_path
                                                }}
                                                saveVideoCount={this.saveVideoCount}
                                                video_type={'highlight'}
                                            />
                                            {/* <div className=''>
                                                <div className="ml-md mt-md">
                                                    <strong>Number of Streams: </strong>
                                                    <span>{orderUploadInfo.highlight_video_view_count}</span>
                                                </div>
                                                <div className="ml-md mt-md">
                                                    <strong>Number of Downloads: </strong>
                                                    <span>{orderUploadInfo.highlight_video_download_count}</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </React.Fragment>
                                }
                            </ThemeModal>
                        </React.Fragment>
                        :
                        <div className='container'>
                            <div style={{marginTop: '10px'}}>
                                {photoGalleryError}
                            </div>
                        </div>
                }

                {
                    isOpen && (
                        <Lightbox
                            mainSrc={orderResources['20x30_mosaic_preview'].file_path}
                            onCloseRequest={this.closeMozaixLightBox}
                            toolbarButtons={
                                [
                                    <div className="fb-share-button"
                                            data-href={orderResources['20x30_mosaic_preview'].file_path}
                                            data-layout="button" data-size="large">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`https://www.facebook.com/sharer/sharer.php?u=${orderResources['20x30_mosaic_preview'].file_path}`, 'popup', 'height=600,width=600')
                                        }} style={{
                                            color: '#fff',
                                            background: '#3b5998',
                                            padding: '5px',
                                            borderRadius: '5px'
                                        }}
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${orderResources['20x30_mosaic_preview'].file_path }`}
                                            className="fb-xfbml-parse-ignore"><i className='fa fa-facebook'></i> Share</a>
                                    </div>,

                                    <i onClick={() => { this.downloadImage(orderResources) }} className='fa fa-download mr-md ml-md lightBoxDownload'></i>,
                                ]
                            }

                            />
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {progress} = state.frontEndUserReducer;
    const {orderUploadInfo} = state.frontEndUserReducer;
    const {orderResources, current_page, perPage, allow_reorder_days_diff, orderImagePath } = state.ordersReducer;
    return {
        progress,
        orderUploadInfo,
        orderResources,
        current_page,
        perPage,
        allow_reorder_days_diff,
        orderImagePath
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeImagesCall: (data, orderId, source) => {
            return dispatch(storeOrderImages(data, orderId, source))
        },
        storeOrderImagesInDbByFrontEndCall: (data, orderId, source) => {
            return dispatch(storeOrderImagesInDbByFrontEnd(data, orderId, source))
        },
        getOrderUploadInfoCall: (orderId) => dispatch(getOrderUploadInfo(orderId)),
        updateOrderUploadCutOffDateCall: (orderId, value) => dispatch(updateOrderUploadCutOffDate(orderId, value)),
        orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => {
            dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole))
        },
        updateOrderSettingsCall: (orderId, value, key) => dispatch(updateOrderSettings(orderId, value, key)),
        getOrderVideos: (orderId) => {
            dispatch(getOrdersResourcesByRole(orderId))
        },
        imagesUploadedMessage: (message) => {
            dispatch(alertActions.success(message))
        },
        imagesFailedMessage: (message) => {
            dispatch(alertActions.error(message))
        },
        getAllowReorderCall: (orderId) => { return dispatch(getAllowReorder(orderId)) },

		getOrderImagesPathCall: (orderId, folder) => { return dispatch(getOrderImagesPath(orderId, folder)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(Uploader))
