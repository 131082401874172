import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleModal, s3StaticPath, uuid } from 'helper/helperFunctions';
import {Transition} from 'react-transition-group';
import {OverlayTrigger, Tooltip, Popover, Row , Col} from 'react-bootstrap';
import FileUpload from 'components/Upload/FileUpload';
import {Link} from 'react-router-dom'
import Validator from 'simple-react-validator'
import VideoPreviewCustom from './VideoPreviewCustom';
import OrderUploadInfoForVideo from 'components/FrontEnd/VideoGallery/OrderUploadInfoForVideo';
import Axios from 'axios';
import { toast } from 'react-toastify';
import {BiLoaderAlt} from 'react-icons/bi';
import { baseUrl } from '../../../helper/ApiCall';
import { showLoader } from 'redux/actions/settings-actions';
import { orderService } from '../../../services/order';
import User from 'helper/User';
import { alertActions } from '../../../redux/actions/alert-actions';
var moment = require("moment");
class VideoGallery extends Component {
	constructor(props) {
        super(props)
        this.videoRef = React.createRef();
		this.videoUploaderKey = 'videoUploader' + User.order('id');
        this.state = {
            files: [],
            videoUploader: {
                firstName: '',
                lastName: ''
            },
            showUploader: true,
            showDoc: true,
            submitVideoProductionModal: false,
            reorderKeepsakeModal: false,
            production_submitted: false,
            showHighlightVideo: false,
            uploadedFiles: 0,
            filesToUpload: 0,
            filesNotUploaded: 0,
            activeTabNo: 1,
            toggleUploader: true,
            toggleUploaderDoc: true,
            submitOrderDocuments: false,
            perPage: '',
            imageData: [],
            total_photo_limit: '',
            isOpen: false,
            showLoader: false,
            is_ucd_after_return_date: false,
            galleryTabsNo: 1,
            videoGallerySection: true,
            photoGallerySection: false,

        }
		
        this.validator = new Validator();
	}
	componentDidMount() { 
		if (localStorage.getItem(this.videoUploaderKey) !== null) {
            this.setState({
                videoUploader: JSON.parse(localStorage.getItem(this.videoUploaderKey))
            })
        }
	 }
	registerVideoUploader = () => {
        if (this.validator.allValid()) {
            const videoUploader = this.state.videoUploader;
            localStorage.setItem(this.videoUploaderKey, JSON.stringify(videoUploader));
            // window.location.reload();
			this.props.success('Name added successfully');

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
	inputChange = (e) => {
        const videoUploader = this.state.videoUploader;
        this.setState({
            videoUploader: {
                ...videoUploader,
                [e.target.name]: e.target.value
            }
        })
    }
	setResourceFile = (key, value) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        value.axiosToken = source;
        this.setState((prevState) => {
            return {
                files: [
                    ...prevState.files,
                    value
                ],
                filesToUpload: prevState.filesToUpload + 1
            }
        });

    }
	removeFile = (key, index) => {
        const files = [...this.state.files]
        files.splice(index, 1);
        this.setState(prevState => (
            {
                files: files,
                filesToUpload: prevState.filesToUpload - 1
            }
        ), () => {
            if (this.state.filesToUpload === this.state.uploadedFiles) {
                this.setState({
                    filesToUpload: 0,
                    uploadedFiles: 0
                })
            }
        });
    }
	saveVideosInDB = (fileData, path) => {
		console.log("saveVideosInDB called", fileData, path);
        this.props.showLoaderCall(false);
        const fd = new FormData();
        fd.append('file_path', path);
        fd.append('file_title', fileData.file_title);
        fd.append('original_file_name', fileData.original_file_name);
        fd.append('file_size', fileData.file_size);
        fd.append('file_type', fileData.file_type);
        fd.append('is_uploaded_from_web', fileData.is_uploaded_from_web);
        fd.append('order_id', this.props.orderUploadInfo.id);
        fd.append('uploaded_by_first_name', this.state.videoUploader.firstName);
        fd.append('uploaded_by_last_name', this.state.videoUploader.lastName);
        orderService.storeOrderVideosByFrontEnd(fd, this.props.orderUploadInfo.id).then(
            response => {
                this.props.showLoaderCall(true);
                // this.setState({
                //     files: [],
                // });
            }
        )
    }
	fetchVideoMetadata = (file) => {
		return new Promise((resolve, reject) => {
		  const video = document.createElement('video');
		  video.preload = 'metadata';
	  
		  video.onloadedmetadata = () => {
			  resolve({
				  duration: video.duration, // In seconds
				  width: video.videoWidth,
				  height: video.videoHeight,
				});
				console.dir(video);
		  };
	  
		  video.onerror = () => {
			reject(new Error('Error loading video metadata.'));
		  };
	  
		  video.src = URL.createObjectURL(file); // Set file as video source
		});
	};
	saveVideos = async () => {
        const files = this.state.files;
    
        let barcode = this.props.orderUploadInfo.barcode;

		for (const file of files) {
			try {
				// Get the presigned URL for the file
				const orderVideoPath = `development/videos/${barcode}/${file.name}`;
				let u_id = uuid().toString();
				console.log("before sending file", file);
				const data = await this.fetchVideoMetadata(file);
				console.log("exif data", data);
				await this.uploadFileInChunks(file, orderVideoPath);
		
			} catch (error) {
				console.error('Error during file upload:', error);
			}
		}
    };
	uploadFileInChunks = async (file, orderVideoPath, chunkSize = 10 * 1024 * 1024) => {
        try {
            // Step 1: Request presigned URLs from backend
            const totalParts = Math.ceil(file.size / chunkSize);
            const { upload_id: uploadId, presigned_urls: presignedUrls } = await this.getMultipartPresignedUrls(orderVideoPath, file, totalParts);
    
            // Step 2: Upload each chunk using presigned URLs
            let uploadedBytes = 0;
            const etags = []; // Store ETag for each part
    
            this.toastId = toast(
                <React.Fragment>Your {file.name} is Uploading. Please wait! {'0%'} <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, 
                { progress: 0, type: toast.TYPE.INFO, position: "bottom-right", autoClose: false }
            );
    
            for (let i = 0; i < totalParts; i++) {
                const start = i * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const chunk = file.slice(start, end);
    
                const { url, part_number: partNumber } = presignedUrls[i];
    
                const response = await fetch(url, {
                    method: 'PUT',
                    body: chunk,
                    headers: { 'Content-Type': file.type },
                });
    
                if (!response.ok) {
                    throw new Error(`Failed to upload chunk ${i + 1}`);
                }
    
                // Collect ETag for each part
                const etag = response.headers.get('ETag');
                etags.push({ PartNumber: partNumber, ETag: etag });
    
                // Update progress
                uploadedBytes += chunk.size;
                const progressPercentage = Math.round((uploadedBytes / file.size) * 100);
                toast.update(this.toastId, {
                    render: (
                        <React.Fragment>Your {file.name} is Uploading. Please wait! {progressPercentage}% <BiLoaderAlt className="loadingSpinner" /></React.Fragment>
                    ),
                    progress: progressPercentage / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                    autoClose: false,
                });
    
                console.log(`Uploaded chunk ${i + 1}/${totalParts}: ${progressPercentage}%`);
            }
    
            // Step 3: Complete Multipart Upload
            await this.completeMultipartUpload(uploadId, orderVideoPath, etags);
    
            // On success
            toast.dismiss(this.toastId);
            toast.success(`${file.name} uploaded successfully!`);

            try {
				
                const fileData = {
                    file_title: file.name,
                    original_file_name: file.name,
                    file_type: file.type,
                    file_size: file.size,
					is_uploaded_from_web: 1
                }
                this.setState({ videouploading: false });
                this.saveVideosInDB(fileData, orderVideoPath);
				const newFiles = this.state.files.filter(item => {
					return item.name !== file.name;
				});
				const uploaded = this.state.uploadedFiles;
				this.setState({
					files: newFiles,
					uploadedFiles: uploaded + 1
				}, () => {
					if (this.state.uploadedFiles === this.state.filesToUpload) {
						this.setState({
							filesToUpload: 0,
							uploadedFiles: 0
						}, () => {
							// this.props.orderImagesCall(User.order('id'), 'original', 'Uploaded-images', 1, '', '', this.state.perPage, User.user.role.name);
							// this.props.getOrderUploadInfoCall(User.order('id'));
							//    imagessuccesscall
						})
					}

				});
            } catch (error) {
                // On upload error, update the toast with error message
                toast.update(this.toastId, {
                    render: `Upload failed: ${error.message}`,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000, // Close after 5 seconds
                });
                console.error('Upload error:', error);
            }
    
        } catch (error) {
            console.error('Error during chunked upload:', error);
            toast.update(this.toastId, {
                render: `Upload failed: ${error.message}`,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }
    };
	// Complete multipart upload
    completeMultipartUpload = async (uploadId, orderVideoPath, etags) => {
        let AdminUser = JSON.parse(localStorage.getItem('user'));
        const response = await fetch(`${baseUrl}complete-multipart-upload`, {
            method: 'POST',
            body: JSON.stringify({
                upload_id: uploadId,
                file_name: orderVideoPath,
                parts: etags,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${AdminUser.token}`,
            },
        });
    
        if (!response.ok) {
            throw new Error('Failed to complete multipart upload.');
        }
    
        return response.json();
    };
	getMultipartPresignedUrls = async (orderVideoPath, file, totalParts) => {
        let AdminUser = JSON.parse(localStorage.getItem('user'));
        const response = await fetch(`${baseUrl}generate-multipart-presigned-urls`, {
            method: 'POST',
            body: JSON.stringify({
                file_name: orderVideoPath,
                file_type: file.type,
                total_parts: totalParts,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${AdminUser.token}`,
            },
        });
    
        if (!response.ok) {
            throw new Error('Failed to get presigned URLs.');
        }
    
        return response.json();
    };
	render() {
		const { orderUploadInfo } = this.props
		return (
			<div>
				<Transition in={this.state.showUploader} timeout={600}>
					{
						state => (
							<div className="uploader_box_contains">
								<div id='top' style={{transition: `all 0.6s`}}
										className="container uploader_box">
									{/* <span onClick={(e) => {
										this.toggleUploader(e, false)
									}}
											className="close_uploader blue_gradient hover_dark"><MdClose/></span> */}
									<div className="row">
										<div className="col-md-12">
											<h3 className="panel_heading small text_left_center_991">Your Video Uploader</h3>

											<div className="uploader_box_container flexElem respFlex">
												<div className="uploader_box_col big">
													<div className="addName infoBox redBox">
														<div><strong className="red_bold">TAKE THE
															CREDIT!</strong> Before uploading, please
															enter the first and last name of the person
															who took the videos that you are about to
															upload then click the "Add Name". The
															photographer`s name will appear under videos
															on the app &amp; website.
														</div>

														<div className="flexElem respFlex addNameForm">
															<div><input name='firstName'
																		value={this.state.videoUploader.firstName}
																		onChange={this.inputChange}
																		type="text"
																		className="themeInput roundedInput hasIcon nameIcon block"
																		placeholder="First Name"/></div>
															<div><input name='lastName'
																		value={this.state.videoUploader.lastName}
																		onChange={this.inputChange}
																		type="text"
																		className="themeInput roundedInput hasIcon nameIcon block"
																		placeholder="Last Name"/></div>
															<div>
																<button
																	onClick={this.registerVideoUploader}
																	className="themeBtn_12 blue_theme_button block hover_dark">Add
																	Name
																</button>
															</div>
														</div>
														{
															this.state.videoUploader.firstName &&
															<div className='mt-md'>Your Photographer
																is <strong>{this.state.videoUploader.firstName + " " + this.state.videoUploader.lastName}</strong>
															</div>
														}
													</div>

													<div className="flexElem respFlex uploadActions">
														<div>
															<OverlayTrigger
																placement="bottom"
																overlay={
																	<Popover id="uploadFileInfo">
																		<table className="mb-xs">
																			<tbody>
																			<tr>
																				<td className="p-sm pl-xs">
																					<span>Acceptable File Types:</span>
																				</td>
																				<td className="pl-sm">
																					<span
																						className="linkColor">.mp4</span>
																				</td>
																			</tr>
																			<tr style={{borderTop: "1px solid #ccc"}}>
																				<td className="p-sm pl-xs">
																					<span>Max File Length:</span>
																				</td>
																				<td className="pl-sm">
																					<span
																						className="linkColor">10 Minutes </span>
																				</td>
																			</tr>
																			</tbody>
																		</table>
																	</Popover>
																}>
																<button onClick={() => {
																	this.uploadForm.open()
																}}
																		className="selectPhoto currAction blue_theme_button hover_dark alignCenter">
																	<img
																		src={s3StaticPath('img/operator/photoUpload.png')}
																		alt="Select Videos To Upload"/>
																	<span>Select Videos To Upload</span>
																	<div style={{display: 'none'}}>
																		<FileUpload
																			ref={el => {
																				this.uploadForm = el
																			}}
																			setResourceFile={this.setResourceFile}
																			multiple={true}
																			allowedFormatsStringMimePro={'video/quicktime, video/mp4'}
																			allowedFormatsStringExtPro={'mov, mp4'}
																		/>
																	</div>
																</button>
															</OverlayTrigger>
														</div>
														<div>
															<button onClick={this.cancelUpload}
																	className="cancelUpload currAction blue_theme_button hover_dark alignCenter">
																<img
																	src={s3StaticPath('img/operator/cancelUpload.png')}
																	alt="Cancel Upload"/>
																<span>Cancel Upload</span>
															</button>
														</div>
													</div>

													<div
														className="uploadDetails flexElem respFlex alignCenter spaceBetween mt-md mb-md">
														<div
															className="text_16">Currently {this.state.uploadedFiles} of {this.state.filesToUpload} uploaded
														</div>

														<div className="text_16 p-xs troubleUploading">
															Trouble Uploading Videos

															<OverlayTrigger
																trigger="click"
																placement="bottom"
																overlay={
																	<Popover id="troubleUploading">
																		<ul style={{
																			listStyle: "disc",
																			paddingLeft: "20px"
																		}}>
																			<li className="pl-xs text_14 lh_2">Try
																				updating your internet
																				browser.
																			</li>
																			<li className="pl-xs mt-sm text_14 lh_2">Try
																				using a different
																				internet browser. We
																				recommend <a
																					target="_blank"
																					rel="noopener noreferrer"
																					href="https://www.mozilla.org/en-US/firefox/new/"
																					className="linkColor">Firefox</a> or <a
																					target="_blank"
																					rel="noopener noreferrer"
																					href="https://www.google.com/chrome/"
																					className="linkColor">Chrome.</a>
																			</li>
																			<li className="pl-xs mt-sm text_14 lh_2">If
																				the above does not work,
																				please <Link
																					target='_blank'
																					className="linkColor"
																					to="Contact_us">Contact
																					Us</Link></li>
																		</ul>
																	</Popover>
																}>
																<span
																	className="ml-xs questionTooltip">?</span>
															</OverlayTrigger>
														</div>
													</div>

													<VideoPreviewCustom
														filesToPreview={
															// this.state.files[0]
															this.state.files.map(file => {
																return file;
															})
														}
														removeFile={this.removeFile}
														type={'full_video'}
													/>

													{/* <div>
														<ImagePreview
															filesToPreview={this.state.files}
															removeFile={this.removeFile}/>
														{this.props.progress !== 0 &&
														<span>{this.props.progress}</span>}
													</div>
													{
														!this.state.filesToUpload ?
															""
															:
															<div className="sec_line">&nbsp;</div>
													}
													<div className='mt-md uploaderProgBars'>
														<table className='table'>
															<tbody>
															{
																this.state.files.map((file, index) => {
																	return (
																		<tr key={file.name.replace(/[- .()]/g, '') + index}>
																			<td className="uploaderFileName"
																				style={{
																					width: "30%",
																					wordBreak: 'break-word'
																				}}>{file.name}</td>
																			<td className="uploaderBar"
																				style={{width: "55%"}}>
																				<span
																					id={file.name.replace(/[- .()]/g, '') + '_speed'}></span>
																				<span
																					id={file.name.replace(/[- .()]/g, '') + '_time'}></span>
																				<span
																					id={file.name.replace(/[- .()]/g, '') + '_loaded'}></span>
																				<span
																					id={file.name.replace(/[- .()]/g, '') + '_total'}></span>
																				<div
																					className="progress">
																					<div
																						className="progress-bar progress-bar-striped progress-bar-success active"
																						role="progressbar"
																						aria-valuenow="100"
																						id={file.name.replace(/[- .()]/g, '')}
																						aria-valuemin="0"
																						aria-valuemax="100"
																						style={{width: "0%"}}>
																						0%
																					</div>
																				</div>
																				<div>
																					{
																						'message' in file &&
																						<ul style={{'backgroundColor': '#f2dede'}} log={console.log("file.message",file.message)}>
																							{
																								file.message.map((m, i) => {
																									return (
																										<li key={i + 'k'}>{m}</li>)
																								})
																							}
																						</ul>
																					}
																				</div>
																			</td>
																			<td className="uploaderFileAction"
																				style={{width: "15%"}}>
																				<i onClick={() => {
																					this.cancelAxiosUpload(file)
																				}}
																					className='fa fa-times'></i>
																			</td>
																		</tr>
																	)
																})
															}
															</tbody>
														</table>
													</div> */}
												</div>

												<OrderUploadInfoForVideo orderUploadInfo={orderUploadInfo}/>
												<button onClick={this.saveVideos}
														className="themeBtn_12 doneBtn blue_theme_button hover_dark">Upload
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					}
				</Transition>
			</div>
		)
	}
}

const mapStateToProps = state => {
   
    return {
       
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
        showLoaderCall: (status) => {
            dispatch(showLoader(status))
        },
		success: (message) => { return dispatch(alertActions.success(message))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoGallery)